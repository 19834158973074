import { Millis } from './time'
import { getAllNotifications } from 'store/reducers/notifications/notifications.selectors'
import { addNotification } from 'store/reducers/notifications/notifications.reducer'
import { release } from './sentry'

export interface Notification {
  id: string
  created: Millis
  expires?: Millis // notification will be automatically dismissed
  text: string
  isPrivate?: boolean // user only
  isDismissed?: boolean
  checks?: {
    version?: string // if set, check if version matches
  }
}

export const fetchNotifications = async (): Promise<Notification[]> => {
  if (typeof fetch === 'undefined') return []
  const res = await fetch('https://jabczyk.com/dose-notifications.json')
  return res.json()
}

export const filterActive = (notifs: Notification[]): Notification[] => {
  const now = Date.now()
  return notifs.filter(n => {
    if (n.checks?.version && n.checks.version !== release) return false

    return !n.isDismissed && (n.expires || Infinity) > now
  })
}

export const addNotifications = async () => {
  const store = require('store/store').store
  const notifications = filterActive(await fetchNotifications())
  const storedNotifications = getAllNotifications(store.getState())
  notifications.forEach(notification => {
    if (storedNotifications.find(n => n.id === notification.id)) return

    store.dispatch(addNotification(notification))
  })
}
