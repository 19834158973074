/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { Section } from 'components/primitives/Section'
import { getSubstanceId, generateUnits } from '../Overview/Dose'
import { useSelector } from 'store/store'
import { getDosesForYears } from 'store/reducers/doses/doses.selectors'
import { Setting } from 'components/App/Settings/shared/Setting'
import { colors } from 'services/theme'
import { useDispatch } from 'react-redux'
import { removeDose, updateDoseNote } from 'store/reducers/doses/doses.reducer'
import { getYear } from 'store/reducers/doses/doses.reducer'
import { fromNow, daysAgo } from 'services/time'
import { Input, Textarea } from 'components/primitives/Input'
import moment from 'moment'
import { DoseCost } from './DoseCost'

interface Props {
  id: string
}

export const DoseInfo: React.FC<RouteComponentProps<Props>> = ({ id = '' }) => {
  const [removeConfirm, setRemoveConfirm] = useState(false)
  const dispatch = useDispatch()
  const substance = getSubstanceId(id)
  const doses = useSelector(getDosesForYears(substance))
  const dose = doses.find(d => d.id === id)

  const _removeDose = () => {
    if (!dose) return
    if (!removeConfirm) return setRemoveConfirm(true)

    dispatch(removeDose({ substance, year: getYear(dose), doseid: dose.id }))
    navigate(`/app/substance/${substance}`)
  }

  const _updateNote = (e: React.FormEvent<HTMLTextAreaElement>) =>
    dispatch(
      updateDoseNote({
        substance,
        year: getYear(dose!),
        doseid: dose!.id,
        note: e.currentTarget.value
      })
    )

  if (id === '') {
    navigate(`/app/substance/${substance}`)
    return null
  }

  const daysAgoNo = daysAgo(dose?.time || 0)

  return (
    <MenuScreen>
      <Section my='big'>
        <HeaderBack centered>Entry Info</HeaderBack>
        <Setting name='Name'>
          {dose?.amount}x {dose?.name}
        </Setting>
        <Setting name='Type' style={{ textTransform: 'capitalize' }}>
          {dose?.type}
        </Setting>
        <Setting name='Time'>
          {fromNow(dose?.time || 0)} {daysAgoNo >= 26 && `(${daysAgoNo}d ago)`}
        </Setting>
        <Setting name='Date'>{moment(dose?.time || 0).format('lll')}</Setting>
        <Setting name='Amounts'>{generateUnits(substance, dose!)}</Setting>
        <DoseCost dose={dose} />
        <Setting
          name='Edit this entry'
          onClick={() => navigate(`/app/substance/dose-edit/${dose?.id}`)}
          chevron
        />
        <Setting
          name={!removeConfirm ? 'Remove this entry' : 'Are you sure?'}
          style={{ color: colors.destructive }}
          onClick={_removeDose}
          chevron
        />
        <Input mt='medium'>
          <Textarea
            maxLength={5000}
            placeholder='Attach a note...'
            defaultValue={dose?.note?.text}
            onBlur={_updateNote}
          />
        </Input>
      </Section>
    </MenuScreen>
  )
}
