import React, { useState } from 'react'
import { Button } from 'components/primitives/Button'
import { readFile } from 'components/App/Settings/Data/YourData'
import { STORAGE } from 'store/persist'
import { Text } from 'components/primitives/Text'

interface Props {
  restart: () => void
}

export const ImportData: React.FC<Props> = ({ restart }) => {
  const [error, setError] = useState(false)

  const importBackup = async (e: React.FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]
    if (!file) return

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let state: any = null
    try {
      state = await readFile(file)
    } catch (err) {
      return setError(true)
    }

    if (typeof window !== 'undefined')
      window.localStorage?.setItem(STORAGE, JSON.stringify(state))
    restart()
  }

  return (
    <>
      <input
        type='file'
        name='file'
        id='file'
        accept='application/JSON'
        style={{ display: 'none' }}
        onChange={importBackup}
      />
      <label htmlFor='file'>
        <Button display='block' variant='clear'>
          Import my data
        </Button>
        {error && (
          <Text as='p' color='destructive' fontSize='small' textAlign='center'>
            Invalid backup file
          </Text>
        )}
      </label>
    </>
  )
}
