import React, { useState } from 'react'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { CenteredRowView, FullCenterView } from 'components/primitives/Views'
import { RouteComponentProps, navigate } from '@reach/router'
import { Section } from 'components/primitives/Section'
import { useForm } from 'react-hook-form'
import { TextInput, Input, FieldError } from 'components/primitives/Input'
import { Box } from 'components/primitives/Box'
import { Button, RoundButton } from 'components/primitives/Button'
import {
  GENERIC_UNITS,
  HUMAN_UNITS
} from 'services/substances/helpers/constants'
import { useDispatch } from 'react-redux'
import { addSubstance } from 'store/reducers/substances/substances.reducer'
import { generateCustomSubstanceState } from 'services/substances/helpers/customSubstance'
import { ColorPicker } from 'components/App/shared/ColorPicker/ColorPicker'
import { Setting } from 'components/App/Settings/shared/Setting'
import { colors } from 'services/theme'
import { DialogScreen } from 'components/App/shared/screens/DialogScreen'
import { trimResolver } from 'services/validation'
import { substanceName } from 'services/format'
import { getSubstanceIds } from 'store/reducers/substances/substances.selectors'
import { useSelector } from 'store/store'

interface Props {
  suggestedName?: string
}

interface CreateData {
  name: string
  unit: string
  color: string
}

const settingStyle: React.CSSProperties = {
  textAlign: 'left',
  background: colors.bg,
  fontWeight: 'bold',
  borderBottom: 'none'
}

export const CreateSubstance: React.FC<RouteComponentProps<Props>> = ({
  suggestedName = ''
}) => {
  const { register, handleSubmit, errors } = useForm<CreateData>({
    validationResolver: trimResolver,
    validationContext: {}
  })
  const substanceIds = useSelector(getSubstanceIds)
  const dispatch = useDispatch()
  const [color, setColor] = useState('#a7a7a7')

  const onSubmit = (data: CreateData) => {
    if (data.name) data.name = substanceName.serialize(data.name)
    const state = generateCustomSubstanceState({ ...data, color }, substanceIds)
    dispatch(addSubstance(state))
    navigate(`/app/substance/${state.id}`)
  }

  return (
    <DialogScreen>
      <FullCenterView>
        <Section>
          <HeaderBack centered>New Substance</HeaderBack>
          <Section my='big'>
            <Name
              register={register}
              suggested={
                suggestedName === '_'
                  ? ''
                  : substanceName.deserialize(suggestedName)
              }
            />
            {errors.name && <FieldError />}
            <Unit register={register} />
            {errors.baseUnit && <FieldError />}
            <Setting name='Color' mode='link' style={settingStyle}>
              <ColorPicker value={color} set={setColor} />
            </Setting>
          </Section>
          <Button variant='clear' onClick={handleSubmit(onSubmit)}>
            Create
          </Button>
        </Section>
      </FullCenterView>
    </DialogScreen>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Registerable = { register: any }

type NameProps = Registerable & { suggested: string }

const Name: React.FC<NameProps> = ({ register, suggested }) => (
  <Box maxWidth='20rem' mx='auto' mt='small'>
    <Input>
      <TextInput
        name='name'
        ref={register({ minLength: 1, maxLength: 20, required: true })}
        required
        maxLength={20}
        placeholder='Substance Name'
        defaultValue={suggested}
      />
    </Input>
  </Box>
)

const Unit: React.FC<Registerable> = ({ register }) => {
  const [selected, setSelected] = useState('mg')

  return (
    <CenteredRowView my='medium'>
      {GENERIC_UNITS.map(unit => (
        <label key={unit}>
          <input
            type='radio'
            name='baseUnit'
            ref={register({ required: true })}
            value={unit}
            style={{ display: 'none' }}
            checked={unit === selected}
            onChange={() => setSelected(unit)}
          />
          <RoundButton variant={selected === unit ? 'default' : 'clear'}>
            {HUMAN_UNITS[unit]}
          </RoundButton>
        </label>
      ))}
    </CenteredRowView>
  )
}
