import React from 'react'
import { Box } from 'components/primitives/Box'
import { Checkbox } from 'components/primitives/Checkbox'
import { Text } from 'components/primitives/Text'

interface Props {
  active?: boolean
  update?: (v: boolean) => void
}

export const SelectableOption: React.FC<Props> = ({
  active,
  update,
  children
}) => {
  return (
    <Box pt='tiny' my='tiny' onClick={() => update && update(!active)}>
      <Checkbox active={active} />
      <Text
        color={active ? 'text' : 'textAccent1'}
        fontWeight='medium'
        ml='1rem'
        lineHeight={1}
      >
        {children}
      </Text>
    </Box>
  )
}
