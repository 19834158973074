import React from 'react'
import { DAY, roundToNearestDay } from 'services/time'
import { H2 } from 'components/primitives/Text'
import {
  Calendar,
  WeekCalendarWrapper
} from 'components/App/shared/Calendar/Calendar'
import { getWeekStart } from 'store/reducers/app/app.selectors'
import { WeekdayNames } from './WeekdayNames'
import { Box } from 'components/primitives/Box'
import { useSelector } from 'store/store'
import { Stats } from 'services/stats/generate'

export const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const MONTH_INITIALS = [
  'J',
  'F',
  'M',
  'A',
  'M',
  'J',
  'J',
  'A',
  'S',
  'O',
  'N',
  'D'
]

export const MONTH_SHORT = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

interface MonthProps {
  data: {
    months: {
      month: number
      year: number
      millis: number
    }[]
    id: string
    activeSubstances: string[]
    map: Stats<string>
  }
  index: number
  style: React.CSSProperties
}

export const MONTH_SIZE = 280

export const Month: React.FC<MonthProps> = React.memo(
  ({ data, index, style }) => {
    const { month, year, millis } = data.months[index]
    const weekStartDay = useSelector(getWeekStart)

    const daysInMonth = (month: number, year: number) =>
      new Date(year, month, 0).getDate()

    const days = daysInMonth(month, year)
    const weekStart = weekStartDay === 'mon' ? -1 : 0

    let daysBefore = new Date(roundToNearestDay(millis)).getDay() + weekStart
    if (daysBefore < 0) daysBefore += 7
    const end = Number(millis + (days - 1) * DAY)

    const monthName = MONTH_NAMES[month - 1]

    return (
      <Box style={style} px='1rem' id={monthName}>
        <H2 fontSize='2.1rem'>{monthName}</H2>
        <WeekCalendarWrapper>
          <WeekdayNames />
          <Filler days={daysBefore} />
          <Calendar days={days} end={end} map={data.map} />
        </WeekCalendarWrapper>
        <br />
      </Box>
    )
  }
)

type FillerProps = { days: number }
const Filler: React.FC<FillerProps> = ({ days }) => (
  <Box flex={`0 1 ${14.2 * days}% !important`}>&zwnj;</Box>
)
