import React from 'react'
import { useSelector } from 'react-redux'
import { isCardBackground } from 'store/reducers/modals/modals.selectors'
import styled from 'styled-components'
import { getUsedTheme } from 'store/reducers/app/app.selectors'

export const CardBackground: React.FC = ({ children }) => {
  const isCard = useSelector(isCardBackground)
  const theme = useSelector(getUsedTheme)

  React.useEffect(() => {
    if (theme !== 'light') return
    console.log('TODO: Set navbar to', isCard ? 'dark' : 'light')
  }, [theme, isCard])

  return (
    <BlackScreen isCard={isCard}>
      <Container isCard={isCard}>{children}</Container>
    </BlackScreen>
  )
}

interface ContainerProps {
  isCard?: boolean
}

const BlackScreen = styled.div<ContainerProps>`
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  ${props =>
    props.isCard &&
    `margin-top: -3.5vh;
  background: black;`}
`

const Container = styled.div<ContainerProps>`
  background: var(--bg);
  border-collapse: separate;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  ${props =>
    props.isCard &&
    `border-radius: 1rem;
    transform: scale(0.9);`}
`
