import React, { useState, FormEvent } from 'react'
import { useSelector } from 'store/store'
import {
  getSubstanceState,
  getTakenNames
} from 'store/reducers/substances/substances.selectors'
import { substanceName } from 'services/format'
import { Setting } from '../../shared/Setting'
import { TextInput } from 'components/primitives/Input'
import { useDispatch } from 'react-redux'
import { updateSubstance } from 'store/reducers/substances/substances.reducer'

interface Props {
  substanceId: string
}

export const SetCustomName: React.FC<Props> = ({ substanceId }) => {
  const dispatch = useDispatch()
  const state = useSelector(getSubstanceState(substanceId))
  const takenNames = useSelector(getTakenNames)
  const deserializedName = substanceName.deserialize(state.name)
  const [isEditing, setIsEditing] = useState(false)

  const handleSubmit = (e: FormEvent<HTMLInputElement>) => {
    const newName =
      substanceName.serialize(e.currentTarget.value) || deserializedName
    if (newName === deserializedName) return setIsEditing(false)
    if (takenNames.includes(newName)) return

    dispatch(updateSubstance({ id: substanceId, name: newName }))
    setIsEditing(false)
  }

  return (
    <Setting name='Name' chevron>
      {!isEditing ? (
        <span onClick={() => setIsEditing(true)}>{deserializedName}</span>
      ) : (
        <TextInput
          defaultValue={deserializedName}
          placeholder={deserializedName}
          autoFocus
          onBlur={handleSubmit}
        />
      )}
    </Setting>
  )
}
