import React from 'react'
import { Text } from 'components/primitives/Text'

interface Props {
  options: string[]
  value: string
  onChange: (val: string) => void
}

export const Select: React.FC<Props> = ({ options, value, onChange }) => {
  return (
    <>
      {options.map((option, i) => (
        <Text
          key={option}
          color={value === option ? 'text' : 'textAccent2'}
          onClick={() => option !== value && onChange(option)}
        >
          {option}
          {i < options.length - 1 && <Text color='textAccent2'> / </Text>}
        </Text>
      ))}
    </>
  )
}
