import React from 'react'
import {
  QuickDose,
  DoseUnits,
  Dose
} from 'services/substances/helpers/Substance'
import { Box } from 'components/primitives/Box'
import {
  ButtonWithDescription,
  ButtonDescription,
  Button
} from 'components/primitives/Button'
import { HUMAN_UNITS } from 'services/substances/helpers/constants'
import { navigate } from '@reach/router'

interface Props {
  quickDoses: QuickDose[]
  selected: Partial<Dose> | QuickDose | null
  setSelected: (dose: QuickDose | null) => void
}

export const Buttons: React.FC<Props> = ({
  quickDoses,
  selected,
  setSelected
}) => {
  const printUnits = (units: DoseUnits) => {
    return Object.keys(units)
      .map(key => units[key] + HUMAN_UNITS[key])
      .join(', ')
  }

  const isSelected = (dose: QuickDose) => selected?.name === dose.name

  const toggleSelected = (dose: QuickDose) =>
    isSelected(dose) ? setSelected(null) : setSelected(dose)

  const custom = () => navigate('add-dose/custom', { replace: true })

  return (
    <>
      {quickDoses.map(quickDose => (
        <Box p='pmedium' key={quickDose.name}>
          <ButtonWithDescription
            variant={isSelected(quickDose) ? 'primary' : 'default'}
            minWidth='18rem'
            onClick={() => toggleSelected(quickDose)}
          >
            {quickDose.name}
            <ButtonDescription>{printUnits(quickDose.units)}</ButtonDescription>
          </ButtonWithDescription>
        </Box>
      ))}
      <Box p='pmedium'>
        <Button minWidth='18rem' onClick={custom}>
          Custom
        </Button>
      </Box>
    </>
  )
}
