import React from 'react'
import { ModalOptions } from 'store/reducers/modals/modals'
import { Notice } from '../Modals/types/Notice'
import { H2, Text } from 'components/primitives/Text'
import { Section } from 'components/primitives/Section'
import { Button } from 'components/primitives/Button'
import { CenteredColumnView } from 'components/primitives/Views'
import { Box } from 'components/primitives/Box'
import { navigate } from '@reach/router'

export const Reminders: React.FC<ModalOptions> = ({ closeModal }) => {
  const configure = () => {
    if (closeModal) closeModal()
    navigate('/app/settings/reminders')
  }

  return (
    <Notice close closeModal={closeModal}>
      <Section my='medium'>
        <H2>Reminders are here!</H2>
        <Text color='textAccent1' fontSize='1.6rem' textAlign='center' as='p'>
          It&apos;s important to log your use regurarly. You can now enable
          check-in reminders to stay consistent.
        </Text>
      </Section>
      <Box maxWidth='20rem' mx='auto' mt='big'>
        <CenteredColumnView>
          <Button variant='primary' onClick={configure}>
            Configure
          </Button>
          <Button variant='clear' onClick={closeModal}>
            No, thanks
          </Button>
        </CenteredColumnView>
      </Box>
    </Notice>
  )
}

export const REMINDERS = 'REMINDERS'

export const REMINDERS_MODAL = {
  type: REMINDERS,
  Component: Reminders,
  options: { background: true, close: true }
}
