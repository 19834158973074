import React from 'react'
import { RoundButton } from 'components/primitives/Button'
import PlusIcon from 'assets/icons/fa/plus-solid.svg'
import styled from 'styled-components'
import { colors } from 'services/theme'
import { FullCenterView } from 'components/primitives/Views'
import { navigate } from '@reach/router'
import { DAY } from 'services/time'
import { store } from 'store/store'
import { setAddDoseTime } from 'store/reducers/doses/doses.reducer'
import { substanceFromDoseId } from 'services/substances/helpers/customSubstance'

export const Plus: React.FC = () => {
  return (
    <FullCenterView>
      <Container onClick={addDose}>
        <FullCenterView>
          <PlusIcon />
        </FullCenterView>
      </Container>
    </FullCenterView>
  )
}

// This crap will be removed when an add-dose modal is added
const addDose = () => {
  const path = window.location.pathname
  const addDoseFor = (s: string) => navigate(`/app/substance/${s}/add-dose`)

  // entry page
  const DOSE_PATH = '/app/substance/dose/'
  if (path.startsWith(DOSE_PATH))
    return addDoseFor(substanceFromDoseId(path.replace(DOSE_PATH, '')))

  // substance calendar
  if (path.startsWith('/app/substance/') && path.endsWith('/calendar'))
    return addDoseFor(
      path.replace('/app/substance/', '').replace('/calendar', '')
    )

  if (path.startsWith('/app/substance/') && !path.includes('/add-dose'))
    return navigate(`${path}/add-dose`)

  const SETTINGS_PATH = '/app/settings/substances/'
  if (path.startsWith(SETTINGS_PATH))
    return addDoseFor(path.replace(SETTINGS_PATH, ''))

  // set add dose time if on a specific day
  if (path.startsWith('/app/day/')) {
    const day = parseInt(path.replace('/app/day/', '')) + 0.5 * DAY
    if (Math.abs(day - Date.now()) > 0.5 * DAY)
      store.dispatch(setAddDoseTime(day))
  }

  navigate('/app/add-dose')
}

const Container = styled(RoundButton)`
  svg {
    fill: ${colors.bg};
    height: 1.8rem;
    margin: -2px 0 -1px 0; // idk it seems to look better
  }
`

Container.defaultProps = {
  variant: 'primary',
  sizeRem: 4.3
}
