import React from 'react'
import { navigate } from '@reach/router'
import { Section } from 'components/primitives/Section'
import { Button } from 'components/primitives/Button'
import { Box } from 'components/primitives/Box'
import { SubstanceState } from 'services/substances/helpers/Substance'
import { useSelector } from 'store/store'
import { getToleratesDrugs } from 'store/reducers/app/app.selectors'
import { substanceName } from 'services/format'
// import { P } from 'components/primitives/Text'

interface Props {
  href: string
  substances: SubstanceState[]
  query: string
}

export const Substances: React.FC<Props> = ({ href, substances, query }) => {
  const _query = query?.toLowerCase()
  const toleratesDrugs = useSelector(getToleratesDrugs)
  const filtered = substances
    .filter(s => _query.length || s.isActive)
    .filter(s => !s.isDrug || toleratesDrugs)
    .filter(s => {
      return (
        !_query ||
        s.name?.toLowerCase().includes(_query) ||
        s.aliases?.find(a => a.includes(_query))
      )
    })
    .sort((a, b) => (b.lastTouched || 0) - (a.lastTouched || 0))
    .sort((a, b) => Number(b.isActive) - Number(a.isActive))

  const select = (id: string) =>
    navigate(href.replace(':id', id), { replace: true })

  const addCustom = () =>
    navigate(`/app/create-substance/${substanceName.serialize(query)}`)

  return (
    <Section my='medium'>
      {filtered.slice(0, 4).map(substance => (
        <Box p='pmedium' key={substance.name}>
          <Button minWidth='18rem' onClick={() => select(substance.id)}>
            {substanceName.deserialize(substance.name)}
          </Button>
        </Box>
      ))}

      {!filtered.length && query && query.trim() !== '' && (
        <Box>
          <Button minWidth='18rem' onClick={addCustom}>
            {query} (new)
          </Button>
        </Box>
      )}

      {/*!filtered.length && (
        <Box mx='auto' maxWidth='20rem'>
          <P my='medium'>
            We couldn&apos;t find the substance you&apos;re looking for.
          </P>
          <P my='medium'>
            Please contact us so we can add it in future updates.
          </P>
        </Box>
      )*/}
    </Section>
  )
}
