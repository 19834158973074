import React, { useMemo, useState } from 'react'
import { Graph } from '../Graph/Graph'
import { useSelector } from 'react-redux'
import {
  getActiveSubstances,
  getSubstances
} from 'store/reducers/substances/substances.selectors'
import { getManyDoses } from 'store/reducers/doses/doses.selectors'
import {
  generateStatsFromDoses,
  generateGraph,
  Range,
  GraphGen
} from 'services/stats/generate'
import { Dose, SubstanceState } from 'services/substances/helpers/Substance'
import { getSubstanceId } from 'components/App/Substance/Overview/Dose'
import { Card } from '../Graph/Card'
import { lastYear } from 'services/time'
import { Legend, Heatmap } from '../Graph/Legend'
import { SelectRange, DEFAULT_RANGE } from '../Graph/SelectRange'

export const TimesUsed: React.FC = React.memo(() => {
  const substances = useSelector(getActiveSubstances)
  const doses = useSelector(getManyDoses(substances, lastYear()))
  const substanceStates = useSelector(getSubstances)

  const [range, setRange] = useState<Range>(DEFAULT_RANGE)
  const { labels, axis, legend, legendHeatmap } = useMemo(
    () => generate(doses, range, substanceStates),
    [doses, range, substanceStates]
  )

  return (
    <Card title='Times Consumed'>
      <SelectRange range={range} setRange={setRange} />
      <Graph labels={labels} axisList={axis} />
      <Legend legend={legend} heatmap={legendHeatmap} />
    </Card>
  )
})

const generate = (
  doses: Dose[],
  range: Range,
  substanceStates: { [key: string]: SubstanceState }
) => {
  const stats = generateStatsFromDoses(doses, range, dose =>
    getSubstanceId(dose.id)
  )
  const { axis: substanceAxis, labels } = (generateGraph[range] as GraphGen<
    string
  >)(stats)
  const legend: Legend = {}
  const legendHeatmap: Heatmap = {}

  const axis = substanceAxis.map(axis => {
    const substanceEntries: { [key: string]: number } = {}

    axis.forEach(substance => {
      if (substanceEntries[substance]) substanceEntries[substance] += 1
      else substanceEntries[substance] = 1

      // legend
      if (substanceStates[substance]) {
        const subName = substanceStates[substance].name
        if (!legend[subName]) legend[subName] = substanceStates[substance].color

        legendHeatmap[subName] = (legendHeatmap[subName] || 0) + 1
      }
    })

    return Object.entries(substanceEntries).map(([substance, amount]) => ({
      value: amount,
      color: substanceStates[substance]?.color,
      name: substanceStates[substance]?.name
    }))
  })

  return { axis, labels, legend, legendHeatmap }
}
