import React from 'react'
import { MenuScreen } from '../shared/screens/MenuScreen'
import { useSelector } from 'store/store'
import {
  getActiveSubstancesStates,
  getActiveSubstances
} from 'store/reducers/substances/substances.selectors'
import { LinkTabs } from './LinkTabs'
import { SubstanceSummary } from './SubstanceSummary'
import { SummaryCalendar } from './SummaryCalendar'
import { RouteComponentProps } from '@reach/router'
import { getLastDose, getManyDoses } from 'store/reducers/doses/doses.selectors'
import { Notifications } from './Notifications/Notifications'
import { Text } from 'components/primitives/Text'
import { ColumnView } from 'components/primitives/Views'
import { generateCalendarMap } from 'services/stats/generate'
import { getIsLocked } from 'store/reducers/app/app.selectors'

const getYearsNeeded = () => {
  const d = new Date()
  const years = [d.getFullYear()]
  if (d.getMonth() === 0) years.push(d.getFullYear() - 1)
  return years
}

export const Dashboard: React.FC<RouteComponentProps> = React.memo(() => {
  const activeSubstancesStates = useSelector(getActiveSubstancesStates)
  const activeSubstances = useSelector(getActiveSubstances)
  const doses = useSelector(getManyDoses(activeSubstances, getYearsNeeded()))
  const map = generateCalendarMap(doses)
  const isLocked = useSelector(getIsLocked)

  const _store = require('store/store').store.getState()
  const lastDose = (substance: string): number =>
    getLastDose(substance)(_store)?.time || 0
  const substanceIds = activeSubstancesStates
    .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))
    .sort((a, b) => lastDose(b.id) - lastDose(a.id))
    .map(s => s.id)

  return (
    <MenuScreen isHidden={isLocked}>
      <LinkTabs />
      <Notifications />
      <SummaryCalendar map={map} />
      {substanceIds.map(substance => (
        <SubstanceSummary key={substance} id={substance} map={map} />
      ))}

      {!substanceIds.length && (
        <ColumnView>
          <Text my='small' textAlign='center' color='textAccent2'>
            Looks like you don&apos;t track any substances yet.
            <br />
            Click the + button to add your first dose!
          </Text>
        </ColumnView>
      )}
    </MenuScreen>
  )
})
