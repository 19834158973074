import React from 'react'
import { AddDose, WrappedProps } from '../AddDose'
import { Section } from 'components/primitives/Section'
import { Input, TextInput, FieldError } from 'components/primitives/Input'
import { useForm } from 'react-hook-form'
import { substances } from 'services/substances/substances'
import { Box } from 'components/primitives/Box'
import { HUMAN_UNITS } from 'services/substances/helpers/constants'
import { Button, buttonHeight } from 'components/primitives/Button'
import { RouteComponentProps, navigate } from '@reach/router'
import { getCurrency } from 'store/reducers/app/app.selectors'
import { useSelector } from 'store/store'

export const AddCustom: React.FC<WrappedProps> = ({
  type,
  id,
  submit,
  defaultValues,
  withCustomCost
}) => {
  const currency = useSelector(getCurrency)
  const { register, handleSubmit, errors } = useForm({ defaultValues })
  const substance = substances?.[id]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onSubmit = (data: any) => {
    let name = data?.name?.trim()
    if (!name || name === '') name = 'Dosage'
    delete data.name
    const customCost = data?.customCost * 100
    delete data.customCost

    submit({ name, customCost, units: data })
  }

  return (
    <>
      <Section my='big'>
        <Input>
          <TextInput
            name='name'
            ref={register({ maxLength: 140 })}
            required
            placeholder='Entry name'
          />
        </Input>
        {errors.name && <FieldError />}

        <Section my='big'>
          {substance.units?.[type].input.map(unit => (
            <div key={unit}>
              <Box maxWidth='10rem' mx='auto' mt='small'>
                <Input>
                  <TextInput
                    name={unit}
                    ref={register({ min: 0, max: 10000, required: true })}
                    required
                    type='number'
                    min='0'
                    max='10000'
                    inputMode='decimal'
                  />
                  {HUMAN_UNITS[unit]}
                </Input>
              </Box>
              {errors[unit] && <FieldError />}
            </div>
          ))}
          {withCustomCost && (
            <Box maxWidth='10rem' mx='auto' mt='small'>
              <Input>
                <TextInput
                  name='customCost'
                  ref={register({ min: 0, max: 10000000, required: true })}
                  required
                  type='number'
                  min='0'
                  step='0.01'
                  max='10000000'
                  inputMode='decimal'
                />
                {currency}
              </Input>
            </Box>
          )}
        </Section>
      </Section>

      <Box minHeight={buttonHeight}>
        <Button variant='clear' onClick={handleSubmit(onSubmit)}>
          {defaultValues ? 'Update' : 'Continue'}
        </Button>
      </Box>
    </>
  )
}

const addCustom = (id: string) => (
  <AddDose
    id={id}
    Wrapped={AddCustom}
    customSubmit
    back={() =>
      navigate(
        window.location.pathname.replace('add-dose/custom', 'add-dose'),
        { replace: true }
      )
    }
  />
)

export const AddCustomRoute: React.FC<RouteComponentProps<{ id: string }>> = ({
  id = ''
}) => addCustom(id)
