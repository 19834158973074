import React from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { ColumnView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { Setting } from '../shared/Setting'
import { useSelector } from 'store/store'
import { getToleratesDrugs } from 'store/reducers/app/app.selectors'
import { RouteComponentProps } from '@reach/router'
import { LockStatus } from '../Lockscreen/LockStatus'
import { colors } from 'services/theme'
import { Share } from './Share'
import { Contact } from './Contact'
import { HeaderBack } from 'components/App/shared/HeaderBack'

export const General: React.FC<RouteComponentProps> = () => {
  const toleratesDrugs = useSelector(getToleratesDrugs)

  return (
    <MenuScreen>
      <ColumnView>
        <Section mt='big'>
          <HeaderBack centered>Settings</HeaderBack>
        </Section>

        <Section my='big'>
          <Setting
            name='Lock screen'
            mode='link'
            chevron
            href='/app/settings/lockscreen'
          >
            <LockStatus />
          </Setting>
          <Setting name='My Data' chevron href='/app/settings/data' />
          <Setting
            name='Preferences'
            mode='link'
            chevron
            href='/app/settings/preferences'
          />
          <Setting
            name='Reminders'
            mode='link'
            chevron
            href='/app/settings/reminders'
          />
          <Setting
            name='Substances'
            mode='link'
            chevron
            href='/app/settings/substances'
          />
          {toleratesDrugs && (
            <Setting
              name="I'm having a bad time"
              chevron
              href='/app/settings/need-help'
              style={{ color: colors.destructive }}
            />
          )}

          <Section mt='big'>
            <Setting
              name='About'
              mode='link'
              chevron
              href='/app/settings/about'
            />
            <Share />
            <Contact />
          </Section>
        </Section>
      </ColumnView>
    </MenuScreen>
  )
}
