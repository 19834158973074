import React, { useState } from 'react'
import { Setting } from '../../shared/Setting'
import { useDispatch } from 'react-redux'
import { BULK_UNIT } from 'services/substances/helpers/constants'
import { SubstanceState } from 'services/substances/helpers/Substance'
import { updateSubstance } from 'store/reducers/substances/substances.reducer'
import { currency } from 'services/substances/helpers/helpers'
import { TextInput } from 'components/primitives/Input'
import { Select } from '../../shared/Select'
import { getPricingUnit } from '../../../../../services/format'

interface Props {
  id: string
  state: SubstanceState
}

const MAX_PRICE = 1e6

export const SetPrice: React.FC<Props> = ({ id, state }) => {
  const dispatch = useDispatch()
  const [price, setPrice] = useState<number | undefined>(
    (state.price || 0) / 100
  )
  const [editingPrice, setEditingPrice] = useState(false)

  if (state.price && price === 0) setPrice(state.price / 100)

  const handlePriceChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setPrice(value ? Number(value) : undefined)
  }

  const handlePriceSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    let price = Number(e.currentTarget.value)
    setEditingPrice(false)

    if (price > MAX_PRICE) price = MAX_PRICE
    if (isNaN(price) || !isFinite(price) || price < 0) return setPrice(0) // setPrice(price ? price : undefined)

    setPrice(price)
    dispatch(
      updateSubstance({
        id,
        price: Math.round(price * 100)
      })
    )
  }

  const setPricingType = (v: string) =>
    dispatch(updateSubstance({ id, isPricePerDose: v === 'per dose' }))

  const bulkUnit = BULK_UNIT[state.baseUnit]
  const pricePer = getPricingUnit(state)

  return (
    <>
      <Setting name='Pricing'>
        <Select
          options={['per dose', `per ${bulkUnit}`]}
          value={`per ${pricePer}`}
          onChange={setPricingType}
        />
      </Setting>

      <Setting name={`Price (per ${pricePer})`} mode='link'>
        {editingPrice ? (
          <TextInput
            type='number'
            step='0.01'
            min='0'
            max='1000'
            defaultValue={price || ''}
            autoFocus
            onChange={handlePriceChange}
            onBlur={handlePriceSubmit}
            inputMode='decimal'
            data-testid='price-input'
          />
        ) : (
          <span onClick={() => setEditingPrice(true)}>
            {currency((price || 0) * 100)}
          </span>
        )}
      </Setting>
    </>
  )
}
