import { useState, useEffect } from 'react'

export const useLoading = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setImmediate(() => setLoading(false))
  })

  return loading
}
