import React from 'react'
import { H2, Text } from 'components/primitives/Text'
import { Section } from 'components/primitives/Section'

interface Props {
  subtitle?: string
}

export const Heading: React.FC<Props> = ({ children, subtitle }) => {
  return (
    <Section py='medium'>
      <H2 lineHeight={1}>{children}</H2>
      {subtitle && (
        <Text fontSize='small' color='textAccent2'>
          {subtitle}
        </Text>
      )}
    </Section>
  )
}
