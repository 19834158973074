import React from 'react'
import { Section } from 'components/primitives/Section'
import { Tab, Tabs } from 'components/primitives/Tabs'

interface Props {
  consumptionTypes: string[]
  selected: string
  setSelected: (type: string) => void
}

export const TypeTabs: React.FC<Props> = ({
  consumptionTypes,
  selected,
  setSelected
}) => {
  return (
    <>
      {consumptionTypes.length > 1 && (
        <Section my='medium'>
          <Tabs>
            {consumptionTypes.map(type => (
              <Tab
                active={selected === type}
                key={type}
                onClick={() => setSelected(type)}
              >
                {type}
              </Tab>
            ))}
          </Tabs>
        </Section>
      )}
    </>
  )
}
