import React from 'react'
import { useDispatch } from 'react-redux'
import { setLocked, setPinCode } from 'store/reducers/app/app.reducer'
import { RouteComponentProps } from '@reach/router'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { Section } from 'components/primitives/Section'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { Setting } from '../shared/Setting'
import { LockStatus } from './LockStatus'
import { useSelector } from 'store/store'
import { getPinCode } from 'store/reducers/app/app.selectors'
import { colors } from 'services/theme'

export const Lockscreen: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const pin = useSelector(getPinCode)

  React.useEffect(() => {
    dispatch(setLocked(true))
  }, [dispatch])

  return (
    <MenuScreen>
      <Section my='big'>
        <HeaderBack centered subtext='Settings'>
          Lockscreen
        </HeaderBack>
        <Setting name='Current protection' mode='link'>
          <LockStatus />
        </Setting>
        <Setting
          name='Set new 4-char code'
          mode='link'
          chevron
          href='/app/settings/lockscreen/set-pin/4'
        />
        <Setting
          name='Set new 6-char code'
          mode='link'
          chevron
          href='/app/settings/lockscreen/set-pin/6'
        />
        {pin && (
          <Setting
            name='Delete code'
            mode='link'
            style={{ color: colors.destructive }}
            onClick={() => dispatch(setPinCode(null))}
          />
        )}
      </Section>
    </MenuScreen>
  )
}
