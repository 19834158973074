import React, { useState } from 'react'
import { RoundedBox, Box } from 'components/primitives/Box'
import styled from 'styled-components'
import {
  PSYCHEDELICS,
  STIMULANTS,
  BENZODIAZEPINES,
  OPIOIDS,
  DISSOCIANTS
} from 'services/substances/default/basicSubstances'
import { shadows } from 'services/theme'
import { Input, TextInput } from 'components/primitives/Input'
import { Text } from 'components/primitives/Text'

interface Props {
  value: string // #hexhex
  set: (hex: string) => void
  colors?: string[]
}

const COLORS = [
  PSYCHEDELICS,
  STIMULANTS,
  BENZODIAZEPINES,
  '#2980b9',
  OPIOIDS,
  '#b33939',
  '#f19066',
  '#cd6133',
  '#6ab04c',
  '#badc58',
  '#95a5a6',
  DISSOCIANTS
]

export const ColorPicker: React.FC<Props> = ({
  value,
  set,
  colors = COLORS
}) => {
  const [open, setOpen] = useState(false)

  const select = (color: string, close = false) => {
    // check if oolor is hex
    if (color.length !== 3 && color.length !== 6) return
    if (parseInt(color, 16).toString(16) !== color.toLowerCase()) return

    set('#' + color)
    if (close) setOpen(false)
  }

  return (
    <>
      <Pick
        bg={value}
        onClick={() => setOpen(!open)}
        data-testid='current-color'
      />
      {open && (
        <Picker>
          {colors.map(color => (
            <Pick
              key={color}
              data-testid={`color-${color}`}
              bg={color}
              mr='small'
              onClick={() => select(color.replace('#', ''), true)}
            />
          ))}
          <Box maxWidth='10rem' data-testid='picker'>
            <Input>
              <Text color='text'>#</Text>
              <TextInput
                placeholder='000000'
                onChange={e => select(e.target.value)}
                data-testid='hex-input'
              />
            </Input>
          </Box>
        </Picker>
      )}
    </>
  )
}

const Picker = styled(Box)`
  border-radius: 1rem;
  margin-left: -15rem;
  position: absolute;
  width: 18rem;
  z-index: 100;
`

Picker.defaultProps = {
  bg: 'bg',
  height: '10rem',
  p: 'pmedium',
  boxShadow: shadows.bottom
}

const Pick = styled(RoundedBox)``

Pick.defaultProps = {
  width: '1.5rem',
  height: '1.5rem',
  radius: '0.75rem',
  display: 'inline-block'
}
