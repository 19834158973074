/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { SelectableOption } from 'components/App/shared/SelectableOption'

interface Option {
  name: string
  action: any
}

interface Props {
  options: Option[]
  onChange: (actions: any) => void
}

export const Options: React.FC<Props> = ({ options, onChange }) => {
  const [chosenActions, setChosenActions] = useState<any[]>([])
  const isActive = (action: any) => chosenActions.includes(action)
  const toggleAction = (active: boolean, action: any) => {
    const newActions = active
      ? [...chosenActions, action]
      : chosenActions.filter(a => a !== action)
    setChosenActions(newActions)
    onChange(newActions)
  }

  return (
    <>
      {options.map(option => (
        <SelectableOption
          key={option.name}
          active={isActive(option.action)}
          update={v => toggleAction(v, option.action)}
        >
          {option.name}
        </SelectableOption>
      ))}
    </>
  )
}
