import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { MenuScreen } from '../shared/screens/MenuScreen'
import {
  RowView,
  FullCenterView,
  CenteredRowView
} from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { H1, Text } from 'components/primitives/Text'
import { Box } from 'components/primitives/Box'
import SettingsIcon from 'assets/icons/fa/cog-regular.svg'
import { colors } from 'services/theme'
import { navigate } from '@reach/router'
import { MoneySpent } from './graphs/MoneySpent/MoneySpent'
import { TimesUsed } from './graphs/TimesUsed/TimesUsed'

export const Profile: React.FC<RouteComponentProps> = () => {
  return (
    <MenuScreen>
      <RowView>
        <Box flex={1} />
        <Box ml='tiny' mt='tiny' onClick={() => navigate('/app/settings')}>
          <FullCenterView>
            <Text as='p' fontWeight='medium' letterSpacing='0.5px' mr='tiny'>
              Settings
            </Text>
            <SettingsIcon fill={colors.text} height={20} />
          </FullCenterView>
        </Box>
      </RowView>
      <Section mb='medium'>
        <H1 textAlign='center'>Insights</H1>
        <CenteredRowView>
          <Text color='textAccent2'>Your use stats</Text>
        </CenteredRowView>
      </Section>
      <TimesUsed />
      <MoneySpent />
    </MenuScreen>
  )
}
