import React from 'react'
import styled from 'styled-components'
import { Text } from 'components/primitives/Text'
import { FullCenterView } from 'components/primitives/Views'
import { colors } from 'services/theme'
import { navigate } from '@reach/router'

interface Props {
  text: string
  href: string
  active?: boolean
}

export const Icon: React.FC<Props> = ({ text, active, href, children }) => {
  const redirect = () => window.location.pathname !== href && navigate(href)

  return (
    <Container active={active} onClick={redirect}>
      <div>
        {children}
        <Text fontSize='1.2rem' lineHeight='1.2rem' mt='-0.5rem' as='div'>
          {text}
        </Text>
      </div>
    </Container>
  )
}

type ContainerProps = { active?: boolean }

const color = (props: ContainerProps) =>
  props.active ? 'var(--menu-icon-active)' : colors.textAccent2

const Container = styled(FullCenterView)<ContainerProps>`
  color: ${color};
  font-weight: 500;
  transition-duration: var(--transition);

  svg {
    fill: ${color};
    height: 2.2rem;
  }
`
