import React, { useState } from 'react'
import SearchIcon from 'assets/icons/fa/search-regular.svg'
import PlusIcon from 'assets/icons/fa/plus-light.svg'
import { colors } from 'services/theme'
import { Section } from 'components/primitives/Section'
import { Box } from 'components/primitives/Box'
import { TextInput } from 'components/primitives/Input'
import { GA, SUBSTANCES } from 'services/analytics'

interface Props {
  value: string
  setValue: (v: string) => void
}

const StartSearching = (label: string) =>
  GA.event({ category: SUBSTANCES, action: 'SearchingStart', label })

export const Search: React.FC<Props> = ({ value, setValue }) => {
  const [active, setActive] = useState(false)
  const activate = () => setActive(true)

  if (active)
    return (
      <Section my='medium'>
        <TextInput
          name='search'
          placeholder='Substance name'
          autoFocus
          value={value}
          maxLength={20}
          onChange={e => setValue(e.target.value)}
          onBlur={e =>
            GA.event({
              category: SUBSTANCES,
              action: 'Search',
              label: e.target.value
            })
          }
        />
      </Section>
    )

  return (
    <Section my='medium' onClick={activate} data-testid='search-buttons'>
      <Box px='small' display='inline-block'>
        <SearchIcon
          height='2rem'
          fill={colors.textAccent2}
          onClick={() => StartSearching('Search')}
        />
      </Box>
      <Box px='small' display='inline-block'>
        <PlusIcon
          height='2rem'
          fill={colors.textAccent2}
          onClick={() => StartSearching('Plus')}
        />
      </Box>
    </Section>
  )
}
