interface Release {
  version: string
  name?: string
  desc?: string
  changes?: Change[]
}

interface Change {
  desc: string
  so?: string
}

export const RELEASES: Release[] = [
  //{ desc: 'Fixed navigation bar color on OnePlus devices', so: 'yaotlyoatz' },
  {
    version: '0.13.0',
    desc: 'Released 02.01.2021',
    changes: [
      { desc: 'Added custom cost setting for quick doses' },
      { desc: 'Added a 3/4 dose multiplier' }
    ]
  },
  {
    version: '0.12.0',
    desc: 'Released 09.11.2020',
    changes: [
      { desc: 'Added check-in reminders' },
      { desc: 'Added a 1/8 dose multiplier' },
      { desc: 'Fixed time selector (iOS)' },
      { desc: 'Fixed "Me" screen crashing' }
    ]
  },
  {
    version: '0.11.0',
    desc: 'Released 25.08.2020',
    changes: [
      { desc: 'Added "per dose" price configuration' },
      { desc: 'Added a "x" unit' },
      { desc: 'Replaced the "times used" stat with "used today" for nicotine' },
      {
        desc:
          'Replaced the "total consumed" stat with "total today" for caffeine'
      },
      { desc: 'Improved the UI' },
      { desc: 'Improved performance' }
    ]
  },
  {
    version: '0.10.0',
    desc: 'Released 25.07.2020',
    changes: [
      { desc: 'Updated the app icon - meet Pedro!', so: 'Lucas' },
      { desc: 'Improved dark theme' },
      { desc: 'Added daily and weekly graphs' },
      { desc: 'Added Vietnamese đồng currency' },
      { desc: 'Fixed a bug regarding % sign in substance names' },
      { desc: 'Fixed PIN prompt scroll issue' }
    ]
  },
  {
    version: '0.9.0',
    desc: 'Released 10.07.2020',
    changes: [
      { desc: 'Added "Total Spent" and "Times Consumed" graphs' },
      { desc: 'Improved app performance' },
      { desc: 'Changed the app menu' },
      { desc: 'Changed some stuff under the hood' },
      { desc: 'Removed screen transition' }
    ]
  },
  {
    version: '0.8.0',
    desc: 'Released 25.06.2020',
    changes: [
      { desc: 'Improved backup inports and exports (iOS)' },
      { desc: 'Updated stats for nicotine' },
      { desc: 'Updated status bar color (iOS)' },
      { desc: 'Fixed menu visibility issue' }
    ]
  },
  {
    version: '0.7.0',
    desc: 'Released 18.06.2020',
    changes: [
      { desc: 'The app is now on the App Store! (iOS)' },
      { desc: 'Added Indian rupee currency' },
      { desc: 'Added share button (iOS)' },
      { desc: 'Updated contact button' },
      { desc: 'Updated continue button on the add entry page' },
      { desc: 'Minor UI improvements' },
      { desc: 'Fixed + button issues' },
      { desc: 'Fixed double-tap issues' }
    ]
  },
  {
    version: '0.6.0',
    desc: 'Released 08.05.2020',
    changes: [
      { desc: 'Updated the calendar screen' },
      { desc: 'Improved navigation between screens' },
      { desc: 'Added "Add substance" button in settings' },
      { desc: 'Fixed minor bugs' }
    ]
  },
  {
    version: '0.5.0',
    desc: 'Released 01.05.2020',
    changes: [
      { desc: 'Improved setup screen' },
      { desc: 'Added substance name setting' },
      { desc: 'Fixed calendar summer time issue' },
      { desc: 'Fixed stat rounding issues' },
      { desc: 'Fixed minor UI issues' }
    ]
  },
  {
    version: '0.4.2',
    name: '🥦',
    desc: 'Released 20.04.2020, 4:20PM GMT+2',
    changes: [
      { desc: 'Added 15 new substances' },
      { desc: 'Added R$ currency' },
      { desc: 'Added entry edit button' },
      { desc: 'Added remove entry confirmation' },
      { desc: 'Added substance colors to entries on calendar day screen' },
      { desc: "Renamed nicotine's Smoked category to Tobacco, added Snus" },
      { desc: 'Fixed + button behaviour on calendar day screen' },
      { desc: 'Fixed minor UI issues' }
    ]
  },
  {
    version: '0.4.1',
    desc: 'Released 07.04.2020',
    changes: [
      { desc: 'Updated currency list', so: 'Moradito' },
      {
        desc: 'Day view entries now show exact time they were taken',
        so: 'VShady'
      },
      { desc: 'Fixed time selector on iOS', so: 'Scrub my PCle d***y' },
      { desc: 'Removed "Donate" button on Android devices' }
    ]
  },
  {
    version: '0.4.0',
    name: '"Beta"',
    desc: 'Released 29.03.2020',
    changes: [
      {
        desc:
          'Added more personalization options: currency, week start day, volume unit'
      },
      { desc: 'You can now set custom cost for each entry' },
      { desc: 'Added iOS splash screens' },
      { desc: 'Minor fixes' }
    ]
  },
  {
    version: '0.3.3',
    desc: 'Released 22.03.2020',
    changes: [
      { desc: 'Added offline support for Android' },
      { desc: 'Minor UI and performance improvements' },
      { desc: 'Fixed time rendering' }
    ]
  },
  {
    version: '0.3.2',
    desc: 'Released 09.03.2020',
    changes: [
      { desc: 'Updated how dosage amounts are shown' },
      { desc: 'Fixed custom substance issues' }
    ]
  },
  {
    version: '0.3.1',
    desc: 'Released 08.03.2020',
    changes: [
      { desc: 'Reduced animation duration' },
      { desc: 'Minor visual changes' },
      { desc: 'Fixed interface rendering' },
      { desc: 'Fixed android backups' }
    ]
  },
  {
    version: '0.3',
    desc: 'Released 02.03.2020',
    changes: [
      { desc: 'Improved app performance', so: 'GatsbyJS/Cloudflare' },
      { desc: 'Updated app icon', so: 'lucasjup' },
      { desc: 'Added a "bad trip" help page', so: 'Psychoskies/cyrilio' },
      { desc: 'Added in-app notifications' },
      { desc: 'Changed amount selector' },
      { desc: 'Device theme is now respected by default' },
      { desc: 'Redesigned PIN prompt, added "forgot code" option' },
      { desc: 'Added edit icon to entry time selector' },
      {
        desc: 'Show exact time in days for "last used" stat',
        so: 'Dragon of the East'
      },
      {
        desc: 'Added amount/time synchronization across screens',
        so: 'Kweyzo'
      },
      { desc: 'Fixed timezone issues', so: 'Kweyzo' }
    ]
  },
  {
    version: '0.2.1',
    desc: 'Released 22.02.2020',
    changes: [
      { desc: 'Added substance removal button', so: 'coopjc' },
      { desc: 'Added exact entry date/time', so: 'Dragon of the East' },
      { desc: 'Changed calendar dots position', so: 'VShady' },
      { desc: 'Changed keyboard type used in dosage input', so: 'coopjc' },
      { desc: 'Entry name is now optional', so: 'Kweyzo' },
      { desc: 'Fixed "last consumed" statistic', so: 'VShady' },
      { desc: 'Fixed sorting issues', so: 'VShady' },
      { desc: 'Fixed blank screen after removing an entry', so: 'coopjc' },
      { desc: 'Fixed android date input issue', so: 'VShady' },
      { desc: 'Fixed uppercase hex codes', so: 'VShady' },
      { desc: 'Fixed calendar colors', so: 'VShady' },
      { desc: 'Fixed substance back button', so: 'yaotlyoatz' }
    ]
  },
  {
    version: '0.2',
    name: '"Alpha"',
    desc: 'Released 22.02.2020',
    changes: [
      {
        desc:
          'Added money spent statistic (prices are configurable in settings)',
        so: 'likeabossb***h'
      },
      { desc: 'Added custom substances support', so: 'GlaciatedNorth' },
      { desc: 'Added PIN code lock', so: '1337coinvb' },
      {
        desc: 'Added entry screen with custom notes and remove button',
        so: 'shanny349/VickShady'
      },
      { desc: 'Added substance color picker' },
      { desc: 'Added dark theme', so: 'Bagwettie' },
      { desc: 'Added temporary app icons' },
      { desc: 'Added changelog' },
      { desc: 'Fixed zoom issue' },
      { desc: 'Entry time can now be changed' }
    ]
  },
  {
    version: '0.1',
    changes: [
      { desc: 'Added basic substance tracking functionality' },
      { desc: 'Asked for user feedback on reddit', so: 'everyone' }
    ]
  }
]
