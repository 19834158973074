import React from 'react'
import { Dose as DoseT } from 'services/substances/helpers/Substance'
import { RoundedBox } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'
import { HUMAN_UNITS, PERCENT } from 'services/substances/helpers/constants'
import { fromNow, exactHour } from 'services/time'
import {
  ColumnView,
  RowView,
  CenteredColumnView
} from 'components/primitives/Views'
import DoseIcon from 'assets/icons/fa/chevron-up-duotone.svg'
import Chevron from 'assets/icons/fa/chevron-right-regular.svg'
import NoteIcon from 'assets/icons/fa/comment-alt-edit-duotone.svg'
import { colors, space } from 'services/theme'
import { substances } from 'services/substances/substances'
import { navigate } from '@reach/router'
import { round } from 'services/substances/helpers/helpers'
import { useSelector } from 'react-redux'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'

interface Props {
  substance: string
  dose: DoseT
  showSubstance?: boolean
  showExactHour?: boolean
}

export const getSubstanceId = (id: string) => {
  const parts = id.split(':')
  if (id.startsWith('custom')) return `custom:${parts[1]}`
  return parts[0]
}

export const generateUnits = (substance: string, dose: DoseT) => {
  if (!dose) return ''

  return substances[substance]?.units[dose.type].input
    .map(unit => {
      const multiplier = unit !== PERCENT ? dose.amount : 1
      const rounded = round(multiplier * dose.units[unit], 100)
      return rounded + HUMAN_UNITS[unit]
    })
    .join(', ')
}

export const Dose: React.FC<Props> = ({
  substance,
  dose,
  showSubstance,
  showExactHour
}) => {
  const state = useSelector(getSubstanceState(substance))
  const units = generateUnits(substance, dose)

  return (
    <RoundedBox
      boxShadow='bottom'
      p='small'
      my='small'
      onClick={() => navigate(`/app/substance/dose/${dose.id}`)}
    >
      <RowView>
        <DoseIcon
          height='2.5rem'
          fill={showSubstance ? state.color : colors.textAccent2}
          style={{ margin: '1.25rem', marginRight: '2rem' }}
        />
        <ColumnView flex={1}>
          <Text fontWeight='medium'>
            {dose.amount}x {dose.name}
            {dose.note?.text && dose.note.text.length && (
              <NoteIcon
                data-testid='note'
                height={14}
                fill={colors.textAccent2}
                style={{ marginLeft: space.tiny }}
              />
            )}
          </Text>
          <Text fontSize='small' color='textAccent2'>
            {units} {showSubstance && `of ${state.name}`}
          </Text>
        </ColumnView>
        <CenteredColumnView>
          <Text fontSize='small' color='textAccent1'>
            {showExactHour ? exactHour(dose.time) : fromNow(dose.time)}
            <Chevron height='1rem' fill={colors.textAccent1} />
          </Text>
        </CenteredColumnView>
      </RowView>
    </RoundedBox>
  )
}
