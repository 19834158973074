import React from 'react'
import { setTolerateDrugs, setTheme } from 'store/reducers/app/app.reducer'
import { prefersDark } from 'components/App/Root'
import { Setting } from '../shared/Setting'
import { useDispatch } from 'react-redux'
import { useSelector } from 'store/store'
import { getTheme, getToleratesDrugs } from 'store/reducers/app/app.selectors'
import { RouteComponentProps } from '@reach/router'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { Section } from 'components/primitives/Section'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { Currency } from './Currency'
import { Weekday } from './Weekday'
import { VolumeUnit } from './VolumeUnit'

const Preferences: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const theme = useSelector(getTheme)
  const toleratesDrugs = useSelector(getToleratesDrugs)

  return (
    <MenuScreen>
      <Section my='big'>
        <HeaderBack centered subtext='Settings'>
          Preferences
        </HeaderBack>
        <Setting
          name='Dark theme'
          mode='toggle'
          value={theme === 'dark' || (theme === 'default' && prefersDark)}
          set={v => dispatch(setTheme(v ? 'dark' : 'light'))}
        />
        <Setting
          name='Tolerate drugs'
          description='Show substances that are less socially tolerated (eg. Kratom, LSD)'
          mode='toggle'
          value={toleratesDrugs}
          set={v => dispatch(setTolerateDrugs(v))}
        />
        <Currency />
        <Weekday />
        <VolumeUnit />
      </Section>
    </MenuScreen>
  )
}

export default Preferences
