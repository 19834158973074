import { RootState } from 'store/store'
import { Dose } from 'services/substances/helpers/Substance'
import { recentYears, Millis } from 'services/time'

const CHECK_YEARS = 10

const _combineArrs = (acc: Dose[], arr: Dose[]) => acc.concat(arr)

export const getDoses = (substance: string, year: number) => (
  state: RootState
) => (state.doses[year] || {})[substance] || []

export const getDosesForYears = (
  substance: string,
  years: number[] = recentYears(CHECK_YEARS)
) => (state: RootState): Dose[] =>
  years
    .map(year => getDoses(substance, year)(state))
    .reduce(_combineArrs, [])
    .filter(d => !d.removed)

export const getManyDoses = (substances: string[], years: number[]) => (
  state: RootState
): Dose[] =>
  substances
    .map(s => getDosesForYears(s, years)(state))
    .reduce(_combineArrs, [])

export const getLastDose = (substance: string) => (state: RootState) => {
  const get = (retry = 0): Dose | null => {
    const year = new Date().getFullYear() - retry
    const doses = getDoses(substance, year)(state).filter(d => !d.removed)

    if (!doses.length) {
      if (retry < CHECK_YEARS) return get(retry + 1)
      return null
    }
    return doses.concat().sort((a, b) => b.time - a.time)[0]
  }

  return get()
}

export const getAddDoseTime = (state: RootState): Millis =>
  state.doses.addDoseTime || Date.now()
export const getAddDoseAmount = (state: RootState): number =>
  state.doses.addDoseAmount
