import React from 'react'
import { Box } from 'components/primitives/Box'
import { RoundButton } from 'components/primitives/Button'
import styled from 'styled-components'

const AMOUNTS = [
  0.125,
  0.25,
  0.33,
  0.5,
  0.75,
  1,
  1.5,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  20,
  30
]
export const CUSTOM_SIGNS: { [value: number]: string } = {
  0.125: '⅛',
  0.25: '¼',
  0.33: '⅓',
  0.5: '½',
  0.75: '¾'
}

interface Props {
  amount: number
  setAmount: (amount: number) => void
}

export const Amount: React.FC<Props> = React.memo(({ amount, setAmount }) => {
  React.useEffect(() => {
    const select = document?.getElementById(`select-amount-${amount}`)
    if (select?.scrollIntoView)
      requestAnimationFrame(() =>
        select.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      )
  }, [amount])

  return (
    <Amounts>
      {AMOUNTS.map(value => (
        <RoundButton
          key={value}
          id={`select-amount-${value}`}
          my='small'
          variant={amount === value ? 'default' : 'clear'}
          onClick={() => setAmount(value)}
          style={{
            flex: '0 0 auto',
            scrollSnapAlign: 'start'
          }}
        >
          {CUSTOM_SIGNS[value] || value + 'x'}
        </RoundButton>
      ))}
    </Amounts>
  )
})

const Amounts = styled(Box)`
  scrollsnaptype: x mandatory;

  ::-webkit-scrollbar {
    display: none;
  }
`

Amounts.defaultProps = {
  m: '0 auto',
  width: '16.5rem',
  height: '7.5rem',
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'auto',
  overflowY: 'hidden'
}
