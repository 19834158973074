import React from 'react'
import { Box, BLOCKING_STYLE } from 'components/primitives/Box'
import { FullCenterView } from 'components/primitives/Views'
import Spinner from 'assets/icons/spinner.svg'
import { Text } from 'components/primitives/Text'

interface Props {
  compact?: boolean
}

export const Loading: React.FC<Props> = ({ compact }) => {
  if (compact)
    return (
      <Text
        fontSize='small'
        as='div'
        my='big'
        textAlign='center'
        color='textAccent1'
      >
        Loading...
      </Text>
    )

  return (
    <Box style={BLOCKING_STYLE} bg='bg'>
      <FullCenterView>
        <Spinner width={30} height={30} />
      </FullCenterView>
    </Box>
  )
}
