import React, { useMemo } from 'react'
import { Axis, Bar, AXIS_HEIGHT } from './Axis'
import { Box } from 'components/primitives/Box'
import { RowView, ColumnView } from 'components/primitives/Views'
import { Text } from 'components/primitives/Text'
import { prettyRound } from 'services/format'
import { Label } from './AxisLabel'

const VALUES = 3

interface Props {
  labels: Label[]
  axisList: Bar[][]
}

export const Graph: React.FC<Props> = React.memo(({ labels, axisList }) => {
  const highestValue = useMemo(() => getHighestValue(axisList), [axisList])

  return (
    <Box>
      <RowView>
        <Box>
          <ColumnView>
            {[...new Array(VALUES)].map((_, i) => (
              <Box height={AXIS_HEIGHT / VALUES} key={i}>
                <Text
                  as='p'
                  fontSize='1.1rem'
                  fontWeight='medium'
                  color='textAccent1'
                >
                  {prettyRound(((VALUES - i) / VALUES) * highestValue)}
                </Text>
              </Box>
            ))}
          </ColumnView>
        </Box>
        {labels.map((label, i) => (
          <Axis
            key={i}
            label={label}
            bars={axisList[i]}
            highestValue={highestValue}
          />
        ))}
      </RowView>
    </Box>
  )
})

const getHighestValue = (rows: Bar[][]): number =>
  Math.max(
    ...rows.map(
      bar => bar.reduce((acc, b) => acc + b.value, 0) //
    )
  )
