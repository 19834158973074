import React from 'react'
import { Tabs, LinkTab } from 'components/primitives/Tabs'
import { Section } from 'components/primitives/Section'
import { getMonthId } from 'services/time'

export const LinkTabs: React.FC = () => {
  return (
    <Section mt='medium'>
      <Tabs>
        <LinkTab href='/app'>Dashboard</LinkTab>
        <LinkTab href={'/app/calendar#' + getMonthId()}>Calendar</LinkTab>
      </Tabs>
    </Section>
  )
}
