import React from 'react'
import { Range } from 'services/stats/generate'
import { Select } from 'components/App/shared/Select'

export const DEFAULT_RANGE = 'weekly'

interface Props {
  range: Range
  setRange: (r: Range) => void
}

export const SelectRange: React.FC<Props> = ({ range, setRange }) => {
  return (
    <Select
      value={range}
      options={[
        { key: 'daily', text: 'Daily' },
        { key: 'weekly', text: 'Weekly' },
        { key: 'monthly', text: 'Monthly' }
      ]}
      onChange={r => setRange(r as Range)}
    />
  )
}
