import React from 'react'
import { Setting } from '../shared/Setting'
import { GA, APP } from 'services/analytics'
import { AndroidInterface } from 'native/android'
declare let Android: AndroidInterface

export const Share: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (typeof navigator === 'undefined' || !navigator.share) return null
  return <Setting name='Share' mode='link' chevron onClick={share} />
}

const SHARE_DATA = {
  title: 'Dose - Drug Journal',
  text: 'Moderate your use of recreational drugs!',
  url: 'https://doseapp.io'
}

const share = () => {
  GA.event({ category: APP, action: 'Share' })

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  if (navigator.share) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      navigator.share(SHARE_DATA)
      return GA.event({ category: APP, action: 'StareSuccess' })
    } catch (err) {
      console.log('Failed to share', err.message)
      return window.open(SHARE_DATA.url)
    }
  }

  if (typeof Android !== 'undefined' && Android.share) {
    Android.share(SHARE_DATA.url)
    return GA.event({ category: APP, action: 'StareSuccess' })
  }
}
