import React, { useMemo, useState } from 'react'
import { Graph } from '../Graph/Graph'
import { useSelector } from 'react-redux'
import { getActiveSubstances } from 'store/reducers/substances/substances.selectors'
import { getManyDoses } from 'store/reducers/doses/doses.selectors'
import {
  generateStatsFromDoses,
  Range,
  generateGraph,
  GraphGen
} from 'services/stats/generate'
import { Dose } from 'services/substances/helpers/Substance'
import { substances } from 'services/substances/substances'
import { getSubstanceId } from 'components/App/Substance/Overview/Dose'
import { Card } from '../Graph/Card'
import { lastYear } from 'services/time'
import { SelectRange, DEFAULT_RANGE } from '../Graph/SelectRange'

export const MoneySpent: React.FC = React.memo(() => {
  const substances = useSelector(getActiveSubstances)
  const doses = useSelector(getManyDoses(substances, lastYear()))

  const [range, setRange] = useState<Range>(DEFAULT_RANGE)
  const { labels, axis } = useMemo(() => generate(doses, range), [doses, range])

  return (
    <Card title='Total Spent'>
      <SelectRange range={range} setRange={setRange} />
      <Graph labels={labels} axisList={axis} />
    </Card>
  )
})

const generate = (doses: Dose[], range: Range) => {
  const stats = generateStatsFromDoses<number>(doses, range, doseToCost)
  const { axis: unreducedAxis, labels } = (generateGraph[range] as GraphGen<
    number
  >)(stats)

  const axis = unreducedAxis.map(values => {
    const value = values.reduce((acc, v) => acc + v, 0)
    return [{ value, color: '#30db60', name: 'Money' }]
  })
  return { axis, labels }
}

const doseToCost = (dose: Dose) => {
  // TODO: initialize per axis, not per dose
  const substance = substances[getSubstanceId(dose.id)]
  if (!substance) return 0
  const stat = substance.stats.find(stat => stat.id === 'substance-total-spent')
  if (!stat) return 0
  return (stat.factory([dose], { asFloat: 1 }, substance.state) as number) || 0
}
