import React, { useState } from 'react'
import { Heading } from '../shared/Heading/Heading'
import { Continue } from '../shared/Continue/Continue'
import {
  setSubstanceActive,
  SET_SUBSTANCE_ACTIVE
} from 'store/reducers/substances/substances.reducer'
import {
  setTolerateDrugs,
  setMedicationUser,
  SET_MEDICATION_USER,
  SET_TOLERATE_DRUGS
} from 'store/reducers/app/app.reducer'
import { Options } from '../shared/Options/Options'
import { AnyAction } from 'redux'
import { ColumnView } from 'components/primitives/Views'
import { multi } from 'store/store'
import { GA, APP } from 'services/analytics'
import { Steps } from '../shared/Steps/Steps'

export const SETUP_SUBSTANCES = [
  {
    name: 'Alcohol',
    action: setSubstanceActive({ id: 'alcohol', value: true })
  },
  {
    name: 'Caffeine',
    action: setSubstanceActive({ id: 'caffeine', value: true })
  },
  {
    name: 'Cannabis',
    action: setSubstanceActive({ id: 'cannabis', value: true })
  },
  {
    name: 'Nicotine',
    action: setSubstanceActive({ id: 'nicotine', value: true })
  },
  {
    name: 'Medication',
    action: setMedicationUser(true)
  },
  {
    name: 'Other Drugs',
    action: setTolerateDrugs(true)
  }
]

interface Props {
  next: () => void
}

export const Substances: React.FC<Props> = ({ next }) => {
  const [chosenActions, setChosenActions] = useState<AnyAction[]>([])

  const submit = () => {
    multi(chosenActions)
    next()
    GA.event({
      category: APP,
      action: 'SetupSubstances',
      label: chosenActions
        .map(a => {
          if (a.type === SET_SUBSTANCE_ACTIVE) return a.payload.id
          if (a.type === SET_TOLERATE_DRUGS) return 'drugs'
          if (a.type === SET_MEDICATION_USER) return 'medication'
          return 'fuckup'
        })
        .sort()
        .join()
    })
  }

  return (
    <ColumnView>
      <Steps step={2} />
      <Heading subtitle='Choose at least one substance.'>
        I&apos;d like to track my use of...
      </Heading>

      <Options options={SETUP_SUBSTANCES} onChange={setChosenActions} />

      <Continue active={!!chosenActions.length} onClick={submit} />
    </ColumnView>
  )
}
