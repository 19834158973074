import React from 'react'
import { Text, P, H1, H2 } from 'components/primitives/Text'
import {
  Button,
  ButtonWithDescription,
  ButtonDescription,
  RoundButton
} from 'components/primitives/Button'
import { Tabs, Tab } from 'components/primitives/Tabs'
import { DialogScreen } from '../shared/screens/DialogScreen'
import { Box, RoundedBox } from 'components/primitives/Box'
import { colors } from 'services/theme'
import { TextInput, Input } from 'components/primitives/Input'
import { RouteComponentProps } from '@reach/router'

// A place for visual testing of primitives
export const Debug: React.FC<RouteComponentProps> = () => {
  return (
    <DialogScreen back='/app'>
      {/* Text */}
      <H1>Header 1</H1>
      <H2>Header 2</H2>

      <Text>Text</Text>
      <Text color='textAccent1'>Accent</Text>
      <Text fontWeight='bold'>Bold</Text>
      <Text fontSize='small'>Small</Text>

      <P>Paragraph</P>
      <P color='textAccent1'>Accent</P>
      <P fontWeight='bold'>Bold</P>

      {/* Buttons */}
      <Button variant='primary'>Primary</Button>
      <Button>Button</Button>
      <Button variant='clear'>Clear</Button>
      <Button display='block'>Full</Button>
      <Button minWidth='25rem'>Minimum 25rem</Button>
      <ButtonWithDescription display='inline-block'>
        With description <ButtonDescription>5%</ButtonDescription>
      </ButtonWithDescription>
      <ButtonWithDescription variant='primary'>
        With description #2 <ButtonDescription>5%</ButtonDescription>
      </ButtonWithDescription>
      <RoundButton>1</RoundButton>
      <RoundButton variant='clear'>2</RoundButton>
      <RoundButton variant='primary'>¼</RoundButton>
      <RoundButton sizeRem={7}>7rem</RoundButton>

      {/* Tabs */}
      <Tabs>
        <Tab active>Dashboard</Tab>
        <Tab>Calendar</Tab>
      </Tabs>

      {/* Boxes */}
      <Box>Box</Box>
      <Box color='accent4' boxShadow='bottom' margin='small'>
        Shadow
      </Box>
      <Box display='inline-block'>Inline</Box>
      <Box bg='accent4' display='inline-block'>
        Inline
      </Box>
      <Box bg='color1' mx='small' my='medium'>
        Medium H margin
      </Box>
      <Box bg='color1' mx='medium' my='small'>
        Medium W margin
      </Box>
      <Box bg='color1' mx='small' my='big'>
        Big H margin
      </Box>
      <Box bg='color1' mx='big' my='small'>
        Big W margin
      </Box>
      <Box bg='accent4' p='psmall'>
        Small padding
      </Box>
      <Box bg='color1' p='pmedium'>
        Medium padding
      </Box>
      <Box bg='accent4' p='pbig'>
        Big padding
      </Box>
      <RoundedBox bg={colors.color1} minHeight='5rem'>
        Rounded
      </RoundedBox>

      {/* Inputs */}
      <br />
      <Box display='inline-block' maxWidth='10rem'>
        <Input>
          <TextInput type='number' placeholder='35' min={0} max={100} />% alc.
        </Input>
      </Box>
      <Box display='inline-block' maxWidth='50rem'>
        <Input>
          <TextInput placeholder='Your Drink Name' />
        </Input>
      </Box>
    </DialogScreen>
  )
}
