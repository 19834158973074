import React from 'react'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { Buttons } from './Buttons'
import { AddDose, WrappedProps } from '../AddDose'
import { RouteComponentProps } from '@reach/router'
import { Text } from 'components/primitives/Text'
import { Box } from 'components/primitives/Box'
import { setAddDoseTime } from 'store/reducers/doses/doses.reducer'
import { useDispatch } from 'react-redux'

export const AddQuick: React.FC<WrappedProps> = ({
  type,
  id,
  dose,
  setDose
}) => {
  const { quickDoses } = useSelector(getSubstanceState(id))
  const filteredQuickDoses = quickDoses.filter(d => d.type === type)

  return (
    <>
      {!filteredQuickDoses.length && (
        <Box mx='auto' my='medium' maxWidth='25rem'>
          <Text color='textAccent2'>
            No quick doses found.
            <br />
            Add them in settings to make logging your doses easier.
          </Text>
        </Box>
      )}
      <Buttons
        quickDoses={filteredQuickDoses}
        selected={dose}
        setSelected={setDose}
      />
    </>
  )
}

const addQuick = (id: string) => {
  const dispatch = useDispatch()
  return (
    <AddDose
      id={id}
      Wrapped={AddQuick}
      back={() => {
        if (typeof window !== 'undefined') window.history.back()
        setTimeout(() => dispatch(setAddDoseTime(0)), 300)
      }}
    />
  )
}

export const AddQuickRoute: React.FC<RouteComponentProps<{ id: string }>> = ({
  id = ''
}) => addQuick(id)
