import React from 'react'
import { Section } from 'components/primitives/Section'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { getSubstancesStates } from 'store/reducers/substances/substances.selectors'
import { useSelector } from 'store/store'
import { Setting } from '../../shared/Setting'
import { RouteComponentProps, navigate } from '@reach/router'
import { getToleratesDrugs } from 'store/reducers/app/app.selectors'
import { substanceName } from 'services/format'
import { colors } from 'services/theme'

export const SubstanceList: React.FC<RouteComponentProps> = () => {
  const substances = useSelector(getSubstancesStates)
  const toleratesDrugs = useSelector(getToleratesDrugs)
  const sorted = substances
    .filter(s => toleratesDrugs || !s.isDrug)
    .sort((a, b) => (a.name ? a.name.localeCompare(b.name) : 0))

  return (
    <MenuScreen>
      <Section my='big'>
        <HeaderBack back='/app/settings' centered subtext='Settings'>
          Substances
        </HeaderBack>
      </Section>

      <Section mb='big'>
        <Setting
          style={{ color: colors.textAccent2 }}
          onClick={() => navigate('/app/create-substance/_')}
          name='+ Create substance'
          chevron
        />
        {sorted.map(substance => (
          <Setting
            key={substance.id}
            name={substanceName.deserialize(substance.name)}
            mode='link'
            href={`/app/settings/substances/${substance.id}`}
            chevron
          />
        ))}
      </Section>
    </MenuScreen>
  )
}
