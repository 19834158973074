import React from 'react'
import { useSelector } from 'store/store'
import { getWeekStart } from 'store/reducers/app/app.selectors'
import { Box } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'

export const WeekdayNames: React.FC = () => {
  const weekStart = useSelector(getWeekStart)

  const DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  if (weekStart === 'mon') DAYS.push(DAYS.shift() as string)

  return (
    <>
      {DAYS.map(day => (
        <Box key={day} mb='medium'>
          <Text
            fontSize='1rem'
            style={{ textTransform: 'uppercase' }}
            as='p'
            textAlign='center'
            color={day === 'sun' ? 'destructive' : 'textAccent1'}
          >
            {day}
          </Text>
        </Box>
      ))}
    </>
  )
}
