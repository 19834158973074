import React from 'react'
import { H1, Text } from 'components/primitives/Text'
import { RowView, CenteredRowView } from 'components/primitives/Views'
import Arrow from 'assets/icons/fa/arrow-left-regular.svg'
import { Box } from 'components/primitives/Box'
import { colors } from 'services/theme'
import { navigate } from '@reach/router'

interface Props {
  back?: string | boolean
  centered?: boolean
  subtext?: string
}

export const HeaderBack: React.FC<Props> = ({
  back,
  children,
  centered,
  subtext
}) => {
  const goBack = () =>
    typeof back === 'string'
      ? navigate(back)
      : typeof window !== 'undefined' && window.history.back()

  return (
    <>
      <RowView style={centered ? { justifyContent: 'center' } : undefined}>
        <Box my='0.7rem' mr='1rem'>
          <Arrow
            height='2.5rem'
            fill={colors.text}
            data-testid='back'
            onClick={goBack}
          />
        </Box>
        <H1>{children}</H1>
      </RowView>
      {subtext && (
        <CenteredRowView>
          <Text color='textAccent2'>{subtext}</Text>
        </CenteredRowView>
      )}
    </>
  )
}
