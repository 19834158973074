import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'components/primitives/Box'
import { ModalOptions } from 'store/reducers/modals/modals'
import { FullCenterView } from 'components/primitives/Views'
import { colors } from 'services/theme'
import CloseIcon from 'assets/icons/fa/times-regular.svg'
import { Transition } from 'react-transition-group'
import { opacityTransition } from '../Modals'

const duration = 300

export const Notice: React.FC<ModalOptions> = ({
  children,
  close = true,
  closeModal
}) => {
  const [visible, setVisible] = useState(false)
  React.useEffect(() => setVisible(true), [])

  const onClose = () => {
    if (!closeModal) return
    setVisible(false)
    closeModal()
  }

  return (
    <FullCenterView>
      <Transition in={visible} timeout={duration}>
        {state => (
          <Container style={opacityTransition[state]}>
            {close && (
              <CloseIcon
                fill={colors.accent3}
                height='1.8rem'
                style={{ float: 'right', zIndex: 101 }}
                onClick={onClose}
              />
            )}
            <Box mt='-0.9rem'>{children}</Box>
          </Container>
        )}
      </Transition>
    </FullCenterView>
  )
}

const Container = styled(Box)`
  border-radius: 1rem;
  transition: all ${duration}ms ease-in-out;
`

Container.defaultProps = {
  width: '85vw',
  maxWidth: '40rem',
  minHeight: '20rem',
  bg: 'bg',
  p: '1rem 1.5rem'
}
