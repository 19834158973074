import React from 'react'
import { Text } from 'components/primitives/Text'

export type Label = string | { text: string; sub: string }

interface Props {
  label: Label
}

export const AxisLabel: React.FC<Props> = ({ label }) => {
  return (
    <Text
      as='div'
      mt='tiny'
      fontSize='1.1rem'
      fontWeight='medium'
      color='textAccent1'
    >
      {typeof label === 'string' ? (
        label
      ) : (
        <>
          {label.text}
          <br />
          <Text fontSize='0.9rem' mt='-0.3rem' as='p'>
            {label.sub}
          </Text>
        </>
      )}
    </Text>
  )
}
