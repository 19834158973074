import React, { useState } from 'react'
import { DialogScreen } from 'components/App/shared/screens/DialogScreen'
import { H1 } from 'components/primitives/Text'
import { FullCenterView } from 'components/primitives/Views'
import { useSelector } from 'store/store'
import { getSubstancesStates } from 'store/reducers/substances/substances.selectors'
import { Section } from 'components/primitives/Section'
import { Search } from './Search'
import { Substances } from './Substances'
import { RouteComponentProps } from '@reach/router'

interface Props {
  href: string
}

export const ChooseSubstance: React.FC<RouteComponentProps<Props>> = ({
  href = ''
}) => {
  const substances = useSelector(getSubstancesStates)
  const [query, setQuery] = useState('')

  return (
    <DialogScreen back='/app'>
      <FullCenterView>
        <Section>
          <H1>
            I would like to add
            <br />
            my usage of...
          </H1>
          <Search value={query} setValue={setQuery} />
          <Substances href={href} substances={substances} query={query} />
        </Section>
      </FullCenterView>
    </DialogScreen>
  )
}
