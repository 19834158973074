import React from 'react'
import { Section } from 'components/primitives/Section'
import { H1, H2, Text } from 'components/primitives/Text'
import { RELEASES } from './releases'
import { font } from 'services/theme'

const cleanList = { listStyle: 'none' }

export const Changelog: React.FC = () => {
  return (
    <Section my='medium'>
      <H1>Changelog</H1>
      {RELEASES.map(release => (
        <Section my='medium' key={release.version}>
          <H2>
            v{release.version} <Text color='textAccent2'>{release.name}</Text>
          </H2>
          <Text color='text'>
            <pre style={{ fontFamily: font }}>{release.desc}</pre>
          </Text>
          <ul style={cleanList}>
            {release.changes?.map(change => (
              <li key={change.desc} style={cleanList}>
                <Text color='textAccent1'>
                  — {change.desc}
                  {change.so && (
                    <Text color='textAccent2' fontSize='small'>
                      <br />
                      Thanks {change.so}!
                    </Text>
                  )}
                </Text>
              </li>
            ))}
          </ul>
        </Section>
      ))}
    </Section>
  )
}
