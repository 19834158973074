// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trimResolver = (data: any) => {
  const errors: { [k: string]: string } = {}

  Object.entries(data).map(([key, value]) => {
    const trimmed = String(value).trim()
    data[key] = trimmed
    if (trimmed === '') {
      errors[key] = 'Empty string'
    }
  })

  return {
    errors,
    values: Object.keys(errors).length ? {} : data
  }
}
