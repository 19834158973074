import React, { useState } from 'react'
import { Setting } from 'components/App/Settings/shared/Setting'
import { Dose } from 'services/substances/helpers/Substance'
import { BULK_UNIT, HUMAN_UNITS } from 'services/substances/helpers/constants'
import { getSubstanceId } from '../Overview/Dose'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { currency } from 'services/substances/helpers/helpers'
import { editDose } from 'store/reducers/doses/doses.reducer'
import { Edit } from 'components/App/shared/Edit'
import { useDispatch } from 'react-redux'
import { TextInput } from 'components/primitives/Input'

interface Props {
  dose?: Dose
}

export const DoseCost: React.FC<Props> = ({ dose }) => {
  const dispatch = useDispatch()
  const substanceId = getSubstanceId(dose?.id || '')
  const substanceState = useSelector(getSubstanceState(substanceId))
  const [isEditing, setIsEditing] = useState(false)

  const setCustomCost = (e: React.FormEvent<HTMLInputElement>) => {
    const cost = Number(e.currentTarget.value)
    if (isNaN(cost) || !isFinite(cost) || cost < 0) return
    dispatch(
      editDose({
        substance: substanceId,
        dose: { ...dose, customCost: cost * 100 } as Dose
      })
    )
  }

  if (!dose) return null

  return (
    <Setting name='Cost'>
      {isEditing ? (
        <TextInput
          defaultValue={(dose?.customCost || 0) / 100}
          onChange={setCustomCost}
          onBlur={() => setIsEditing(false)}
          autoFocus
          type='number'
          step='0.01'
          min='0'
          max='1000'
          inputMode='decimal'
        />
      ) : dose?.customCost ? (
        <span onClick={() => setIsEditing(true)}>
          {currency(dose?.customCost)}
          <Edit />
        </span>
      ) : (
        <span onClick={() => setIsEditing(true)}>
          {currency(substanceState.price || 0)}/
          {HUMAN_UNITS[BULK_UNIT[substanceState.baseUnit]]}
          <Edit />
        </span>
      )}
    </Setting>
  )
}
