import React from 'react'
import EditIcon from 'assets/icons/fa/edit-solid.svg'
import { colors, space } from 'services/theme'

export const Edit: React.FC = () => (
  <EditIcon
    fill={colors.textAccent1}
    height='1.4rem'
    style={{ marginLeft: space.tiny }}
  />
)
