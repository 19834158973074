import React from 'react'
import { Millis, DAY } from 'services/time'
import { Dose } from 'services/substances/helpers/Substance'
import { RoundButton } from 'components/primitives/Button'
import { Box } from 'components/primitives/Box'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { getSubstances } from 'store/reducers/substances/substances.selectors'
import { useSelector } from 'store/store'
import { RowView, ColumnView } from 'components/primitives/Views'
import { Text } from 'components/primitives/Text'
import { navigate } from '@reach/router'

interface Props {
  day: Millis
  color?: string
  small?: boolean
  dontLink?: boolean
  takenToday: string[]
  substance?: string
}

export const dayFilter = (day: Millis) => (d: Dose) =>
  d.time >= day && d.time <= day + DAY

export const getDosesForDay = (doses: Dose[], day: Millis): Dose[] =>
  doses.filter(dayFilter(day))

export const CalendarDot: React.FC<Props> = ({
  day,
  color,
  small,
  dontLink,
  takenToday,
  substance
}) => {
  const states = useSelector(getSubstances)

  const monthDay = new Date(day).getDate()
  const isFuture = Date.now() - day < 0

  const substancesToday = Array.from(new Set(takenToday)).slice(0, 6)

  if (small && substance)
    return (
      <Box flex={1}>
        <RoundButton
          data-testid='dot'
          bg={
            substancesToday.includes(substance) ? color || 'color1' : 'accent3'
          }
          sizeRem={1.5}
          m='0 auto'
          display='block'
        ></RoundButton>
      </Box>
    )

  return (
    <Box
      flex={1}
      mb='1.5rem'
      data-testid='dot'
      onClick={() => !dontLink && navigate(`/app/day/${day}`)}
    >
      <ColumnView>
        <Text
          textAlign='center'
          fontWeight='bold'
          fontSize='small'
          color={isFuture ? 'textAccent2' : 'text'}
          marginTop='-1.2rem'
        >
          {monthDay}
        </Text>
        <SubstanceDots>
          {substancesToday.map(id => (
            <SubstanceDot
              key={id}
              bg={states?.[id]?.color || 'var(--accent-2)'}
              data-testid={`dot-${id}`}
            />
          ))}
        </SubstanceDots>
      </ColumnView>
    </Box>
  )
}

const SubstanceDots = styled(RowView)`
  align-items: center;
  align-self: flex-end;
  flex-wrap: wrap;
  height: 1.2rem;
  justify-content: center;
  margin: 0 auto;
  max-width: 2.4rem;
`

const SubstanceDot = styled.div<ColorProps>`
  border-radius: 0.2rem;
  height: 0.4rem;
  margin: 0.1rem 0.2rem;
  width: 0.4rem;
  ${color}
`
