import React from 'react'
import { Setting } from '../shared/Setting'
import { useSelector, multi } from 'store/store'
import { getVolumeUnit } from 'store/reducers/app/app.selectors'
import {
  setVolumeUnit,
  VolumeUnit as Unit
} from 'store/reducers/app/app.reducer'
import { Select } from '../shared/Select'
import { updateDosesVolumeUnit } from 'store/reducers/doses/doses.reducer'
import { updateQuickVolumeUnit } from 'store/reducers/substances/substances.reducer'

export const VolumeUnit: React.FC = () => {
  const volUnit = useSelector(getVolumeUnit)

  const setUnit = (v: Unit) =>
    multi([
      setVolumeUnit(v),
      updateDosesVolumeUnit(v),
      updateQuickVolumeUnit(v)
    ])

  return (
    <Setting name='Volume Unit'>
      <Select
        options={['ml', 'oz']}
        value={volUnit}
        onChange={v => setUnit(v as Unit)}
      />
    </Setting>
  )
}
