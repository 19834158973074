import React, { useState } from 'react'
import styled from 'styled-components'
import { Box } from 'components/primitives/Box'
import { colors } from 'services/theme'
import { CenteredRowView } from 'components/primitives/Views'
import { Icon } from './Icon'
import HomeIcon from 'assets/icons/fa/signal-alt-3-solid.svg'
//import SettingsIcon from 'assets/icons/fa/cog-solid.svg'
import ProfileIcon from 'assets/icons/fa/user-solid.svg'
import { Plus } from './Plus'

export const Menu: React.FC = () => {
  const [visible, setVisible] = useState(false)
  const path = typeof window !== 'undefined' ? window.location.pathname : '/app'

  React.useEffect(() => {
    shouldBeVisible(path)
      ? setVisible(true)
      : setTimeout(() => setVisible(false), 150)
  })

  if (!visible) return null
  return (
    <Container>
      <CenteredRowView
        style={{ justifyContent: 'space-evenly', height: '5.5rem' }}
      >
        <Icon
          href='/app'
          text='Home'
          active={
            path.startsWith('/app/substance/') ||
            path.startsWith('/app/calendar') ||
            path.startsWith('/app/day') ||
            ['/', '/app', '/app/'].includes(path)
          }
        >
          <div
            data-testid='menu-home'
            style={{ marginRight: '0.6rem', transform: 'scaleX(-1)' }}
          >
            <HomeIcon />
          </div>
        </Icon>

        <Plus />

        <Icon
          href='/app/profile'
          text='Me'
          active={path.startsWith('/app/profile')}
        >
          <ProfileIcon />
        </Icon>

        {/*<Icon
          href='/app/settings'
          text='Settings'
          active={path.startsWith('/app/settings')}
        >
          <SettingsIcon />
        </Icon>*/}
      </CenteredRowView>
    </Container>
  )
}

const Container = styled(Box)`
  border: 1px solid ${colors.accent3};
  bottom: 0;
  justify-content: space-evenly;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 20;
`

Container.defaultProps = { bg: 'bg' }

const shouldBeVisible = (path: string) => {
  const NO_TRANSITION_ROUTES = [
    '/app/goals',
    '/app/profile',
    '/app/settings',
    '/app/calendar',
    '/app/day/'
  ]

  const isSubstanceScreen =
    path.startsWith('/app/substance/') &&
    [4, 5].includes(path.split('/').length)

  return (
    (!!NO_TRANSITION_ROUTES.find(r => path.startsWith(r)) ||
      path === '/app' ||
      path === '/app/' ||
      isSubstanceScreen) &&
    !path.includes('/add-dose') &&
    !path.includes('/add-quick')
  )
}
