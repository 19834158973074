import React from 'react'
import { useSelector } from 'store/store'
import { getPinCode } from 'store/reducers/app/app.selectors'

export const LockStatus: React.FC = () => {
  const pin = useSelector(getPinCode)

  if (!pin) return <>No protection</>
  return <>{pin.length}-char code</>
}
