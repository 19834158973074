import React from 'react'
import { HeaderBack } from '../../shared/HeaderBack'
import { daysFromNow, getCalendarId } from 'services/time'
import { Error404 } from '../../Error404/Error404'
import { Section } from 'components/primitives/Section'
import moment from 'moment'
import { useSelector } from 'store/store'
import { getActiveSubstances } from 'store/reducers/substances/substances.selectors'
import { getManyDoses } from 'store/reducers/doses/doses.selectors'
import { dayFilter } from '../../shared/Calendar/CalendarDot'
import { Dose } from '../../Substance/Overview/Dose'
import { RouteComponentProps } from '@reach/router'
import { getSubstanceId } from 'components/App/Substance/Overview/Dose'
import { Text } from 'components/primitives/Text'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'

interface Props {
  day: string // millis
}

export const Day: React.FC<RouteComponentProps<Props>> = ({ day }) => {
  const millis = parseInt(day || '' + Date.now())
  const activeSubstances = useSelector(getActiveSubstances)
  const _doses = useSelector(
    getManyDoses(activeSubstances, [new Date(millis).getFullYear()])
  )
  const doses = _doses.filter(dayFilter(millis)).sort((a, b) => a.time - b.time)

  if (isNaN(millis) || !isFinite(millis)) return <Error404 />

  return (
    <MenuScreen>
      <Section my='big'>
        <HeaderBack
          centered
          subtext={daysFromNow(millis)}
          back={`/app/calendar#` + getCalendarId(millis)}
        >
          {moment(millis).format('ll')}
        </HeaderBack>
      </Section>
      {doses.map(dose => (
        <Dose
          key={dose.id}
          substance={getSubstanceId(dose.id)}
          dose={dose}
          showSubstance
          showExactHour
        />
      ))}

      {!doses.length && (
        <Text as='p' color='textAccent2' textAlign='center'>
          Nothing consumed on this day!
        </Text>
      )}
    </MenuScreen>
  )
}
