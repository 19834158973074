import React from 'react'
import { RowView, ColumnView } from 'components/primitives/Views'
import { RoundedBox } from 'components/primitives/Box'
import { Dose } from 'services/substances/helpers/Substance'
import { space, colors } from 'services/theme'
import { Text } from 'components/primitives/Text'
import Pills from 'assets/icons/fa/pills-duotone.svg'
import { substances } from 'services/substances/substances'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'

export const STAT_NAMES: { [k: string]: string } = {
  'substance-consumed': 'Consumed',
  'substance-consumed-today': 'Today',
  'substance-last-use': 'Last Used',
  'substance-times-used': 'Times Used',
  'substance-times-used-today': 'Used Today',
  'substance-total-spent': 'Total Spent',

  'alcohol-ml-consumed': 'Drank',
  'alcohol-drinks-consumed': 'Drinks',
  'cannabis-edibles-milligrams': 'Edibles',
  'cannabis-smoked-grams': 'Smoked',
  'nicotine-smoked': 'Tobacco',
  'nicotine-vaped': 'Vaped'
}

interface Props {
  substance: string
  doses: Dose[]
}

export const Stats: React.FC<Props> = ({ substance, doses }) => {
  const state = useSelector(getSubstanceState(substance))
  const stats = substances[substance]?.generateStats(doses, state)

  return (
    <RowView mx={'-' + space.tiny} flexWrap='wrap'>
      {stats.map(stat => {
        const Icon = stat.icon || Pills
        return (
          <RoundedBox
            boxShadow='bottom'
            p='small'
            m='tiny'
            flex='1 40%'
            key={stat.id}
          >
            <RowView>
              <Icon
                height='2.5rem'
                fill={colors.color1}
                style={{ margin: '1.25rem 1.5rem 1.25rem 1rem' }}
              />
              <ColumnView flex={1}>
                <Text fontWeight='bold'>{STAT_NAMES[stat.id] || stat.id}</Text>
                <Text color='textAccent2' fontSize='small'>
                  {stat.value || 'No data'}
                </Text>
              </ColumnView>
            </RowView>
          </RoundedBox>
        )
      })}
    </RowView>
  )
}
