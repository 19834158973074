import React from 'react'
import styled from 'styled-components'
import { Box } from './Box'
import Check from 'assets/icons/fa/check-regular.svg'
import { colors } from 'services/theme'

interface CheckboxProps {
  active?: boolean
}

const CheckboxBox = styled(Box)<CheckboxProps>`
  border: 2px solid ${colors.accent3};
  border-radius: 0.5rem;
  display: inline-block;
  height: 2.3rem;
  line-height: 2.3rem;
  text-align: center;
  transition: var(--transition);
  width: 2.3rem;
  svg {
    opacity: 0;
  }

  ${props =>
    props.active &&
    `
    background: ${colors.color1};
    border-color: ${colors.color1};
    svg { opacity: 1; }
  `}
`

export const Checkbox: React.FC<CheckboxProps> = ({ active }) => (
  <CheckboxBox
    active={active}
    data-testid={`checkbox-${active ? 'active' : 'inactive'}`}
  >
    <Check fill={colors.bg} height='1.5rem' />
  </CheckboxBox>
)
