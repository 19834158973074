import React from 'react'
import Root from 'components/App/Root'
import { Provider } from 'react-redux'
import store from 'store/store'
import { addNotifications } from 'services/notifications'
import SEO from 'components/shared/SEO'
import { release } from 'services/sentry'
import { AndroidInterface } from 'native/android'
import { FCM_TOKEN } from 'store/persist'
declare let Android: AndroidInterface

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <SEO title={'v' + release} />
      <Root />
      {typeof window !== 'undefined' &&
        window.location.hostname === 'dosejournal.com' && (
          <iframe
            id='dose-iframe'
            width={1}
            height={1}
            src='https://doseapp.io/iframe'
            style={{ border: 'none', opacity: 0.01 }}
          ></iframe>
        )}
    </Provider>
  )
}

export default App

// Double tap zoom
const delay = 500
const minZoomTouchDelta = 50

let lastTapAt = 0
let lastClientX = 0
let lastClientY = 0

const preventDoubleTapZoom = (event: TouchEvent) => {
  if (event.touches.length > 1) return

  const tapAt = new Date().getTime()
  const timeDiff = tapAt - lastTapAt
  const { clientX, clientY } = event.touches[0]
  const xDiff = Math.abs(lastClientX - clientX)
  const yDiff = Math.abs(lastClientY - clientY)
  if (
    xDiff < minZoomTouchDelta &&
    yDiff < minZoomTouchDelta &&
    event.touches.length === 1 &&
    timeDiff < delay
  ) {
    event.preventDefault()
    // event.target.click()
  }
  lastClientX = clientX
  lastClientY = clientY
  lastTapAt = tapAt
}

// Prevent zooming
const preventZoom = (e: Event) => {
  e.preventDefault()
  if (document) document.body.style.zoom = ''
}

if (typeof document !== 'undefined') {
  document.addEventListener('gesturestart', preventZoom)
  document.addEventListener('gesturechange', preventZoom)
  document.addEventListener('gestureend', preventZoom)
  document.addEventListener('touchstart', preventDoubleTapZoom)
}

// Fetch notifications
addNotifications()

// Get FCM token on Android
const getAndroidFCMToken = async () => {
  if (typeof Android === 'undefined') return
  console.log('[FCM] getting token from android')

  const token = Android.getFCM()
  if (!token || !token.length) return setTimeout(getAndroidFCMToken, 1000)
  localStorage.setItem(FCM_TOKEN, token)
  console.log('[FCM] got token=' + token)
}
getAndroidFCMToken()
