import React, { useState } from 'react'
import { DialogScreen } from 'components/App/shared/screens/DialogScreen'
import { FullCenterView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { Box } from 'components/primitives/Box'
import { buttonHeight, Button } from 'components/primitives/Button'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { TypeTabs } from './TypeTabs'
import { Dose, QuickDose } from 'services/substances/helpers/Substance'
import { Amount } from './Amount'
import { useDispatch } from 'react-redux'
import {
  addDose,
  setAddDoseTime,
  setAddDoseAmount
} from 'store/reducers/doses/doses.reducer'
import { setSubstanceActive } from 'store/reducers/substances/substances.reducer'
import { Error404 } from 'components/App/Error404/Error404'
import { DOSES, GA } from 'services/analytics'
import uuidv4 from 'uuid/v4'
import { Time } from './Time'
import {
  getAddDoseTime,
  getAddDoseAmount
} from 'store/reducers/doses/doses.selectors'
import { GRAM, MILLIGRAM } from 'services/substances/helpers/constants'

export interface WrappedProps {
  id: string
  type: string
  dose: Partial<Dose> | null
  setDose: (dose: Partial<Dose> | null) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  submit: (dose: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValues?: any
  withCustomCost?: boolean
}

interface Props {
  id: string
  Wrapped: React.ComponentType<WrappedProps>
  customSubmit?: boolean
  back?: () => void
}

export const AddDose: React.FC<Props> = ({
  id,
  Wrapped,
  customSubmit,
  back
}) => {
  const dispatch = useDispatch()
  const state = useSelector(getSubstanceState(id))
  const [selectedType, setSelectedType] = useState(state?.consumptionTypes?.[0])
  const [dose, setDose] = useState<QuickDose | Partial<Dose> | null>(null)

  const time = useSelector(getAddDoseTime)
  const amount = useSelector(getAddDoseAmount)

  const setTime = (time: number) => dispatch(setAddDoseTime(time))
  const setAmount = (amount: number) => dispatch(setAddDoseAmount(amount))

  const add = (input: Partial<Dose> | undefined) => {
    const _dose: Dose = {
      id: `${id}:${uuidv4()}`,
      // @ts-ignore
      type: selectedType,
      amount,
      time: time,
      ...(input as QuickDose | Dose)
    }
    if (_dose.customCost) _dose.customCost = _dose.customCost * _dose.amount
    dispatch(
      addDose({
        substance: id,
        dose: _dose
      })
    )
    GA.event({
      category: DOSES,
      action: 'AddDose',
      label: id,
      value:
        id === 'cannabis'
          ? ((input as Dose).units[GRAM] || 0) * 1000 +
          ((input as Dose).units[MILLIGRAM] || 0)
          : undefined
    })
    dispatch(setSubstanceActive({ id, value: true }))
    dispatch(setAddDoseTime(0))
    dispatch(setAddDoseAmount(1))
    window.history.back()
  }

  if (!state) return <Error404 />

  return (
    <DialogScreen back={back}>
      <FullCenterView>
        <Section>
          <Time time={time} setTime={setTime} />
          <Amount amount={amount} setAmount={setAmount} />

          <TypeTabs
            consumptionTypes={state.consumptionTypes}
            selected={selectedType}
            setSelected={setSelectedType}
          />

          <Wrapped
            type={selectedType}
            dose={dose}
            setDose={setDose}
            id={id}
            submit={add}
          />

          {!customSubmit && (
            <Box minHeight={buttonHeight} mt='1rem'>
              {dose && (
                <Button
                  variant='primary'
                  minWidth='18rem'
                  onClick={() => add(dose)}
                >
                  Continue
                </Button>
              )}
            </Box>
          )}
        </Section>
      </FullCenterView>
    </DialogScreen>
  )
}
