import { SubstanceState } from './substances/helpers/Substance'
import { BULK_UNIT } from './substances/helpers/constants'

export const substanceName = {
  serialize: (name: string) =>
    name
      .trim()
      .replace(/#/g, '!h!')
      .replace(/\//g, '!s!')
      .replace(/\?/g, '!q!')
      .replace(/%/g, '!p!'),

  deserialize: (name: string) =>
    name
      .replace(/!h!/g, '#')
      .replace(/!s!/g, '/')
      .replace(/!q!/g, '?')
      .replace(/!p!/g, '%')
}

export const prettyRound = (value: number) => {
  let fixed = 0
  if (Math.floor(value) != value) {
    if (value < 10) fixed = 1
    if (value < 1) fixed = 2
  }

  return value.toFixed(fixed)
}

export const getPricingUnit = (state: SubstanceState) =>
  state.isPricePerDose ? 'dose' : BULK_UNIT[state.baseUnit]
