import React, { useState } from 'react'
import { Section } from 'components/primitives/Section'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { AddCustom } from 'components/App/Substance/AddDose/AddCustom/AddCustom'
import { TypeTabs } from 'components/App/Substance/AddDose/TypeTabs'
import { FullCenterView } from 'components/primitives/Views'
import { useDispatch } from 'react-redux'
import { Dose } from 'services/substances/helpers/Substance'
import { RouteComponentProps } from '@reach/router'
import { Error404 } from 'components/App/Error404/Error404'
import { CONSUMED } from 'services/substances/helpers/constants'
import { DialogScreen } from 'components/App/shared/screens/DialogScreen'
import { Time } from '../AddDose/Time'
import { Amount } from '../AddDose/Amount'
import { getDosesForYears } from 'store/reducers/doses/doses.selectors'
import { getSubstanceId } from '../Overview/Dose'
import { editDose } from 'store/reducers/doses/doses.reducer'

interface Props {
  id: string
}

export const EditDose: React.FC<RouteComponentProps<Props>> = ({ id = '' }) => {
  const dispatch = useDispatch()
  const substanceId = getSubstanceId(id)
  const state = useSelector(getSubstanceState(substanceId))
  const consumptionTypes = state.consumptionTypes || [CONSUMED]
  const [type, setType] = useState(consumptionTypes[0])
  const doses = useSelector(getDosesForYears(substanceId))
  const dose = doses.find(d => d.id === id)

  const [time, setTime] = useState(Date.now())
  const [amount, setAmount] = useState(1)

  React.useEffect(() => {
    setTime(dose?.time || Date.now())
    setAmount(dose?.amount || 1)
  }, [dose])

  const submit = (dose: Dose) => {
    dose.id = id
    dose.type = type
    dose.time = time
    dose.amount = amount
    dispatch(editDose({ substance: substanceId, dose }))
    window.history.back()
  }

  if (!state || !dose) return <Error404 />

  return (
    <DialogScreen back='back'>
      <FullCenterView>
        <Section>
          <Time time={time} setTime={setTime} />
          <Amount amount={amount} setAmount={setAmount} />

          <TypeTabs
            consumptionTypes={consumptionTypes}
            selected={type}
            setSelected={setType}
          />

          <AddCustom
            type={type}
            id={substanceId}
            submit={submit}
            dose={null}
            setDose={d => d}
            defaultValues={getFormValues(dose)}
          />
        </Section>
      </FullCenterView>
    </DialogScreen>
  )
}

const getFormValues = (dose: Dose) => {
  return {
    name: dose.name,
    ...dose.units
  }
}
