import React, { useMemo } from 'react'
import { Box } from 'components/primitives/Box'
import { FullCenterView, CenteredRowView } from 'components/primitives/Views'
import { colors } from 'services/theme'
import styled from 'styled-components'
import { AxisLabel, Label } from './AxisLabel'

export interface Bar {
  name: string
  color: string
  value: number
}

interface Props {
  label: Label
  bars: Bar[]
  highestValue: number
}

export const AXIS_HEIGHT = 120

export const Axis: React.FC<Props> = ({ label, bars, highestValue }) => {
  const sortedBars = useMemo(() => bars.sort((a, b) => a.value - b.value), [
    bars
  ])

  return (
    <Box flex={1}>
      <FullCenterView>
        <div>
          <CenteredRowView>
            <Box
              height={AXIS_HEIGHT}
              mx='auto'
              display='flex'
              alignItems='flex-end'
            >
              <BarsWrapper>
                {sortedBars.map(bar => (
                  <Box
                    key={bar.name}
                    bg={bar.color}
                    width={10}
                    height={
                      bar.value ? (bar.value / highestValue) * AXIS_HEIGHT : 0
                    }
                    data-testid='bar'
                  />
                ))}
              </BarsWrapper>
            </Box>
          </CenteredRowView>
          <AxisLabel label={label} />
        </div>
      </FullCenterView>
    </Box>
  )
}

const BarsWrapper = styled(Box)`
  border-radius: 5px;
  overflow: hidden;

  div:not(:first-child) {
    border-top: 1px solid ${colors.accent2};
  }
`
