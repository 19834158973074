import React, { useRef } from 'react'
import { MINUTE } from 'services/time'
import { useSelector } from 'store/store'
import { getActiveSubstances } from 'store/reducers/substances/substances.selectors'
import { FixedSizeList } from 'react-window'
import { Month, MONTH_NAMES, MONTH_SIZE } from './Month'
import { getManyDoses } from 'store/reducers/doses/doses.selectors'
import { generateCalendarMap } from 'services/stats/generate'

interface Props {
  id?: string
  year: string
}

export const YearCalendar: React.FC<Props> = React.memo(({ id, year }) => {
  const activeSubstances = useSelector(getActiveSubstances)
  const doses = useSelector(
    getManyDoses(id ? [id] : activeSubstances, [parseInt(year)])
  )
  const map = generateCalendarMap(doses)

  const months = MONTH_NAMES.map((_, i) => {
    const monthNo = i + 1
    return {
      millis:
        // uses the only format that works on safari
        new Date(
          `${year}-${monthNo > 9 ? monthNo : '0' + monthNo}-01`
        ).getTime() +
        // new Date(string) returns UTC, we need local time
        new Date().getTimezoneOffset() * MINUTE,
      month: monthNo,
      year
    }
  })

  const monthsRef = useRef<FixedSizeList | null>(null)
  React.useEffect(() => {
    const scrollMonth = parseInt(window.location.hash.replace('#', ''))
    if (scrollMonth) monthsRef?.current?.scrollTo(scrollMonth * MONTH_SIZE)
  }, [monthsRef])

  return (
    <>
      <FixedSizeList
        itemData={{ months, map }}
        height={typeof window !== 'undefined' ? window.innerHeight - 166 : 0}
        width={typeof window !== 'undefined' ? window.innerWidth - 20 : 0}
        itemSize={MONTH_SIZE}
        itemCount={12}
        ref={monthsRef}
      >
        {Month}
      </FixedSizeList>
    </>
  )
})
