import React from 'react'
import { Section } from 'components/primitives/Section'
import { RoundedBox } from 'components/primitives/Box'
import { CenteredRowView } from 'components/primitives/Views'

interface Props {
  steps?: number
  step: number
}

export const Steps: React.FC<Props> = ({ steps = 4, step }) => {
  return (
    <Section mt='small'>
      <CenteredRowView>
        {[...new Array(steps)].map((_, i) => (
          <RoundedBox
            key={i}
            width={8}
            height={8}
            display='inline-block'
            mx={5}
            bg={i + 1 === step ? 'color1' : 'accent3'}
          />
        ))}
      </CenteredRowView>
    </Section>
  )
}
