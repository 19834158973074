import React from 'react'
import { Heading } from '../shared/Heading/Heading'
import { Continue } from '../shared/Continue/Continue'
import { ColumnView } from 'components/primitives/Views'
import { Steps } from '../shared/Steps/Steps'
import { Button } from 'components/primitives/Button'
import { ReminderOptions } from 'components/App/Settings/Reminders/ReminderOptions/ReminderOptions'
import { Section } from 'components/primitives/Section'
import { useDispatch } from 'react-redux'
import { updateReminders } from 'store/reducers/app/app.reducer'

interface Props {
  next: () => void
}

export const Reminders: React.FC<Props> = ({ next }) => {
  const dispatch = useDispatch()

  const submit = () => {
    dispatch(updateReminders({ enabled: true }))
    next()
  }

  return (
    <ColumnView>
      <Steps step={4} />
      <Heading
        subtitle="It's important to log your use regurarly. You can
enable check-in reminders to stay consistent."
      >
        Turn on reminders?
      </Heading>

      <Section my='big'>
        <ReminderOptions />
      </Section>

      <Continue active onClick={submit} text='Turn on'>
        <Button display='block' variant='clear' onClick={() => next()}>
          No, thanks
        </Button>
      </Continue>
    </ColumnView>
  )
}
