import React, { useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useDispatch } from 'react-redux'
import { setPinCode } from 'store/reducers/app/app.reducer'
import { PinScreen } from './PinScreen'

interface Props {
  length: string // 4 or 6
}

export const PinSet: React.FC<RouteComponentProps<Props>> = ({
  length = '4'
}) => {
  const digits = parseInt(length)
  const dispatch = useDispatch()
  const [mode, setMode] = useState<'insert' | 'repeat'>('insert')
  const [pin, setPin] = useState('')

  const handleChange = (newPin: string) => {
    if (mode === 'insert') {
      setPin(newPin)
      if (newPin.length === digits) setMode('repeat')
      return
    }
    if (newPin === pin) {
      dispatch(setPinCode(newPin))
      navigate('/app/settings')
    }
  }

  return (
    <PinScreen
      onChange={handleChange}
      digitsNo={digits}
      text={mode === 'insert' ? 'Enter your new code' : 'Repeat your code'}
      options={['goback', 'cancel']}
    />
  )
}
