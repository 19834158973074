import React, { useState } from 'react'
import { Heading } from '../shared/Heading/Heading'
import { Continue } from '../shared/Continue/Continue'
import { addUserGoal } from 'store/reducers/app/app.reducer'
import { Options } from '../shared/Options/Options'
import { AnyAction } from 'redux'
import { ColumnView } from 'components/primitives/Views'
import { multi } from 'store/store'
import { GA, APP } from 'services/analytics'
import { Steps } from '../shared/Steps/Steps'

export const GOALS = [
  {
    name: 'Moderating my use',
    action: addUserGoal('moderating')
  },
  {
    name: 'Quitting a substance',
    action: addUserGoal('quitting')
  },
  {
    name: 'Knowing how much I consume',
    action: addUserGoal('knowing')
  },
  {
    name: 'Tracking my medication intake',
    action: addUserGoal('medication')
  },
  {
    name: 'Keeping memories',
    action: addUserGoal('memories')
  }
]

interface Props {
  next: () => void
}

export const UserGoals: React.FC<Props> = ({ next }) => {
  const [chosenActions, setChosenActions] = useState<AnyAction[]>([])

  const submit = () => {
    multi(chosenActions)
    next()
    GA.event({
      category: APP,
      action: 'SetupSubstances',
      label: chosenActions
        .map(a => a.payload)
        .sort()
        .join()
    })
  }

  return (
    <ColumnView>
      <Steps step={3} />

      <Heading subtitle='Choose at least one.'>My goals are...</Heading>

      <Options options={GOALS} onChange={setChosenActions} />

      <Continue active={!!chosenActions.length} onClick={submit} />
    </ColumnView>
  )
}
