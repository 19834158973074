import React from 'react'
import { Box, BLOCKING_STYLE } from 'components/primitives/Box'
import { FullCenterView } from 'components/primitives/Views'
import { Text } from 'components/primitives/Text'

export const Restart: React.FC = () => {
  return (
    <Box style={BLOCKING_STYLE} bg='bg'>
      <FullCenterView>
        <Text>Please restart the app.</Text>
      </FullCenterView>
    </Box>
  )
}
