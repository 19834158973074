import React from 'react'
import { ReminderOptions } from './ReminderOptions/ReminderOptions'
import { RouteComponentProps } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { updateReminders } from 'store/reducers/app/app.reducer'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { Section } from 'components/primitives/Section'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { getRemindersData } from 'store/reducers/app/app.selectors'
import { Setting } from '../shared/Setting'

export const Reminders: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const { enabled } = useSelector(getRemindersData)

  return (
    <MenuScreen>
      <Section m='big'>
        <HeaderBack centered subtext='Settings'>
          Reminders
        </HeaderBack>
      </Section>

      <ReminderOptions />

      <Section mt='big'>
        <Setting
          name='Push notifications'
          mode='toggle'
          value={enabled}
          set={enabled => dispatch(updateReminders({ enabled }))}
        />
      </Section>
    </MenuScreen>
  )
}
