import React from 'react'
import { Box } from 'components/primitives/Box'
import { RowView } from 'components/primitives/Views'
import { Text } from 'components/primitives/Text'

interface Props {
  options: { key: string; text: string }[]
  value: string
  onChange: (value: string) => void
}

export const Select: React.FC<Props> = ({ options, value, onChange }) => {
  const change = (key: string) => {
    if (key === value) return
    onChange(key)
  }

  const offset =
    (options.findIndex(option => option.key === value) / options.length) * 100

  return (
    <Box bg='accent1' p='0.4rem' mb='small' style={{ borderRadius: '0.8rem' }}>
      <RowView style={{ zIndex: 2 }}>
        {options.map(option => (
          <Box key={option.key} flex={1} onClick={() => change(option.key)}>
            <Text as='p' textAlign='center' fontSize='small'>
              {option.text}
            </Text>
          </Box>
        ))}
      </RowView>
      <Box
        bg='bg'
        style={{ borderRadius: '0.4rem', transitionDuration: '0.2s' }}
        width={100 / options.length + '%'}
        height='2.2rem'
        mt='-2.2rem'
        ml={offset + '%'}
      />
    </Box>
  )
}
