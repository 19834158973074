import React, { useState } from 'react'
import { Setting } from '../../shared/Setting'
import { colors } from 'services/theme'
import { useDispatch } from 'react-redux'
import { deleteSubstance } from 'store/reducers/substances/substances.reducer'
import { navigate } from '@reach/router'

interface Props {
  id: string
}

export const DeleteCustom: React.FC<Props> = ({ id }) => {
  const dispatch = useDispatch()
  const [confirmation, setConfirmation] = useState(false)

  const _deleteSubstance = () => {
    if (!confirmation) return setConfirmation(true)
    dispatch(deleteSubstance(id))
    navigate('/app/settings/substances')
  }

  if (!id.startsWith('custom:')) return null

  return (
    <Setting
      name={!confirmation ? 'Delete Substance' : 'Are you sure?'}
      style={{ color: colors.destructive }}
      onClick={_deleteSubstance}
    />
  )
}
