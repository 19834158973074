import React from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { Section } from 'components/primitives/Section'
import { Setting } from '../../shared/Setting'
import {
  setSubstanceActive,
  deleteQuickDose,
  updateSubstance
} from 'store/reducers/substances/substances.reducer'
import { useDispatch } from 'react-redux'
import { H2 } from 'components/primitives/Text'
import { Box } from 'components/primitives/Box'
import Delete from 'assets/icons/fa/times-regular.svg'
import { colors } from 'services/theme'
import { RowView } from 'components/primitives/Views'
import { navigate, RouteComponentProps } from '@reach/router'
import { DOSES, GA, SUBSTANCES } from 'services/analytics'
import { HUMAN_UNITS } from 'services/substances/helpers/constants'
import { DoseUnits } from 'services/substances/helpers/Substance'
import { SetPrice } from './SetPrice'
import { ColorPicker } from 'components/App/shared/ColorPicker/ColorPicker'
import { DeleteCustom } from './DeleteCustom'
import { substanceName } from 'services/format'
import { SetCustomName } from './SetCustomName'

interface Props {
  id: string
}

export const Substance: React.FC<RouteComponentProps<Props>> = ({
  id = ''
}) => {
  const state = useSelector(getSubstanceState(id))
  const dispatch = useDispatch()

  const addQuickDose = () =>
    navigate(`/app/settings/substances/${id}/add-quick`)

  const generateUnits = (units: DoseUnits) =>
    Object.keys(units)
      .map(u => units[u] + HUMAN_UNITS[u])
      .join(', ')

  const setColor = (color: string) =>
    dispatch(
      updateSubstance({
        id,
        color
      })
    )

  if (!state) return null

  return (
    <MenuScreen>
      <Section mt='big'>
        <HeaderBack back='/app/settings/substances' centered subtext='Settings'>
          {substanceName.deserialize(state.name)}
        </HeaderBack>
      </Section>

      <Section mt='big'>
        <SetCustomName substanceId={id} />
        <Setting
          name='Visible'
          mode='toggle'
          value={state.isActive}
          set={value => {
            dispatch(setSubstanceActive({ id: state.id, value }))
            GA.event({
              category: SUBSTANCES,
              action: 'SetActive',
              label: `${id}:${value ? 'yes' : 'no'}`
            })
          }}
        />
        <Setting name='Color' mode='link'>
          <ColorPicker value={state.color} set={setColor} />
        </Setting>

        <SetPrice id={id} state={state} />
        <DeleteCustom id={id} />
      </Section>

      <Section my='big'>
        <H2>Quick Doses</H2>
        {state.quickDoses.map(quickDose => (
          <Setting key={quickDose.name} name={quickDose.name} mode='link'>
            <RowView>
              {generateUnits(quickDose.units)}
              <Delete
                height='2rem'
                fill={colors.destructive}
                style={{ margin: '0.2rem 0 0.2rem 0.5rem' }}
                data-testid='delete-quick'
                onClick={() => {
                  dispatch(
                    deleteQuickDose({ id: state.id, name: quickDose.name })
                  )
                  GA.event({
                    category: DOSES,
                    action: 'DeleteQuick',
                    label: id
                  })
                }}
              />
            </RowView>
          </Setting>
        ))}

        {state.quickDoses.length < 5 ? (
          <Box padding='pmedium' color='textAccent2' onClick={addQuickDose}>
            + Add quick dose
          </Box>
        ) : (
          <Box padding='pmedium' color='textAccent2'>
            You&apos;ve reached quick doses limit
          </Box>
        )}
      </Section>
    </MenuScreen>
  )
}
