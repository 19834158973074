import React, { useState } from 'react'
import { Setting } from '../shared/Setting'
import { useSelector } from 'store/store'
import { getCurrency } from 'store/reducers/app/app.selectors'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { colors, fontSizes } from 'services/theme'
import { fontFamily } from 'styled-system'
import { setCurrency } from 'store/reducers/app/app.reducer'

const CURRENCIES = [
  '$',
  '€',
  '£',
  '¥',
  'zł',
  'R$',
  'kn',
  'ft',
  'lei',
  'kr',
  'Kč',
  '₽',
  '₺',
  '₴',
  '₡',
  '₹',
  '₫'
]

export const Currency: React.FC = () => {
  const currency = useSelector(getCurrency)
  const dispatch = useDispatch()
  const [isEditing, setIsEditing] = useState(false)

  //const setCurrency = (e: React.FormEvent<HTMLSelectElement>) =>

  return (
    <Setting name='Currency' chevron>
      {isEditing ? (
        <Select
          autoFocus
          onChange={e => dispatch(setCurrency(e.currentTarget.value))}
          onBlur={() => setIsEditing(false)}
          defaultValue={currency}
        >
          {CURRENCIES.map(c => (
            <option key={c} value={c}>
              {c}
            </option>
          ))}
        </Select>
      ) : (
        <div onClick={() => setIsEditing(true)}>{currency}</div>
      )}
    </Setting>
  )
}

const Select = styled.select`
  -webkit-appearance: none;
  appearance: none;
  background: ${colors.accent1};
  border: none;
  color: ${colors.textAccent1};
  font-family: ${fontFamily};
  font-size: ${fontSizes.regular};

  :focus {
    outline: none;
  }

  option {
    text-align: center;
    :selected {
      background: red;
      color: ${colors.text};
    }
  }
`
