import React from 'react'
import {
  REMINDERS,
  REMINDERS_MODAL
} from 'components/App/shared/notices/Reminders'

export interface Modal {
  type: string
  Component: React.FC
  options: ModalOptions
}

export interface ModalOptions {
  close?: boolean
  background?: boolean
  cardBackground?: boolean
  closeModal?: () => void
  zIndex?: number
}

export const MODALS: { [type: string]: Modal } = {
  [REMINDERS]: REMINDERS_MODAL
}
