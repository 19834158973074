import React, { memo } from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { H2, Text } from 'components/primitives/Text'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { LinkTabs } from '../LinkTabs'
import { CenteredRowView } from 'components/primitives/Views'
import { Dose } from './Dose'
import { getDosesForYears } from 'store/reducers/doses/doses.selectors'
import { Stats } from './Stats'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { Error404 } from 'components/App/Error404/Error404'
import { RouteComponentProps } from '@reach/router'
import { substanceName } from 'services/format'

interface Props {
  id: string
}

export const Overview: React.FC<RouteComponentProps<Props>> = memo(
  ({ id = '' }) => {
    const state = useSelector(getSubstanceState(id))
    const doses = useSelector(getDosesForYears(id))
    const sorted = doses.sort((a, b) => b.time - a.time).slice(0, 20)

    if (!state) return <Error404 />

    return (
      <MenuScreen>
        <LinkTabs id={id} />
        <HeaderBack back='/app'>
          {substanceName.deserialize(state.name)}
        </HeaderBack>

        <Stats substance={id} doses={doses} />

        <H2 mt='small'>Recent Activity</H2>

        {sorted.map(dose => (
          <Dose key={dose.id} dose={dose} substance={id} />
        ))}

        {!sorted.length && (
          <CenteredRowView>
            <Text my='big' textAlign='center' color='textAccent1'>
              No activity found.
              <br />
              Click the plus button to add new doses.
            </Text>
          </CenteredRowView>
        )}
      </MenuScreen>
    )
  }
)
