import React, { useState } from 'react'
import { YearCalendar as Year } from 'components/App/shared/Calendar/YearCalendar/YearCalendar'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { LinkTabs } from '../LinkTabs'
import { RouteComponentProps } from '@reach/router'
import { YearSelector } from 'components/App/shared/Calendar/YearCalendar/YearSelector'
import { useLoading } from 'components/App/shared/hooks'
import { Loading } from 'components/App/shared/Loading'

interface Props {
  id: string
}

export const Calendar: React.FC<RouteComponentProps<Props>> = ({ id = '' }) => {
  const [year, setYear] = useState(new Date().getFullYear())
  const loading = useLoading()

  return (
    <MenuScreen>
      <LinkTabs id={id} />
      <YearSelector year={year} setYear={setYear} />
      {loading ? <Loading compact /> : <Year year={'' + year} id={id} />}
    </MenuScreen>
  )
}
