import React from 'react'
import { Setting } from '../shared/Setting'
import { useSelector } from 'react-redux'
import { getUser } from 'store/reducers/app/app.selectors'
import { release } from 'services/sentry'
import { getSystem, isAndroid } from 'services/device'
import { DAY } from 'services/time'

export const Contact: React.FC = () => {
  const user = useSelector(getUser)
  const openMail = () => {
    const daysSinceInstall = ((Date.now() - user.since) / DAY).toFixed(2)
    const body = [
      'Hey!',
      '',
      '-----',
      `v${release}-${getSystem()}`,
      `${daysSinceInstall}--${user.id}`
    ]

    if (!isAndroid()) {
      window.open(
        `mailto:hi@doseapp.io?subject=Dose App Feedback&body=${body.join(
          '%0D%0A'
        )}`
      )
    }
  }

  return (
    <Setting
      name='Contact us (hi@doseapp.io)'
      mode='link'
      chevron
      onClick={openMail}
    />
  )
}
