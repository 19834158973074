import React from 'react'
import { CenteredRowView } from 'components/primitives/Views'
import { Button } from 'components/primitives/Button'

interface Props {
  active?: boolean
  onClick?: () => void
  text?: string
}

export const Continue: React.FC<Props> = ({
  active,
  onClick,
  text,
  children
}) => (
  <CenteredRowView>
    <div
      style={{
        position: 'fixed',
        bottom: '3.5rem',
        width: '20rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxSizing: 'border-box'
      }}
    >
      <Button
        display='block'
        variant={active ? 'primary' : 'default'}
        onClick={() => active && onClick && onClick()}
      >
        {text || 'Continue'}
      </Button>
      {children}
    </div>
  </CenteredRowView>
)
