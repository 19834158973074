import React, { useMemo } from 'react'
import { Text } from 'components/primitives/Text'
import { Box } from 'components/primitives/Box'
import styled from 'styled-components'

export interface Legend {
  [substance: string]: string
}

export interface Heatmap {
  [substance: string]: number
}

interface Props {
  legend: Legend
  heatmap: Heatmap
}

export const Legend: React.FC<Props> = ({ legend, heatmap }) => {
  const sorted = useMemo(() => sortLegend(legend, heatmap), [legend, heatmap])

  return (
    <Text as='div' textAlign='center'>
      {sorted.map(([name, color]) => (
        <Text
          as='div'
          style={{ display: 'inline-block' }}
          fontSize='1.2rem'
          fontWeight='medium'
          px='tiny'
          key={name}
        >
          <ColorDot bg={color} data-testid={`dot-${color}`} />
          <span data-testid='legend-name'>{name}</span>
          <Text ml='tiny' color='textAccent2'>
            {heatmap[name]}
          </Text>
        </Text>
      ))}
    </Text>
  )
}

const sortLegend = (legend: Legend, heatmap: Heatmap) =>
  Object.entries(legend).sort(([a], [b]) => heatmap[b] - heatmap[a])

const ColorDot = styled(Box)`
  border-radius: 50%;
  display: inline-block;
  height: 0.7rem;
  margin-right: 0.3rem;
  width: 0.7rem;
`
