import React, { useState } from 'react'
import { FullCenterView, RowView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { Box, BLOCKING_STYLE } from 'components/primitives/Box'
import { Text, H1 } from 'components/primitives/Text'
import { RoundButton } from 'components/primitives/Button'
import { colors } from 'services/theme'
import { navigate } from '@reach/router'
import { ForgotCode } from './ForgotCode'
import styled from 'styled-components'

const KEYBOARD = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]

interface Props {
  digitsNo?: number
  text: string
  onChange: (pin: string) => void
  options?: Option[]
}

type Option = 'cancel' | 'goback' | 'forgot'

export const PinScreen: React.FC<Props> = ({
  digitsNo,
  onChange,
  text,
  options = ['forgot', 'cancel']
}) => {
  const [digits, setDigits] = useState<string[]>([])
  const [forgot, setForgot] = useState(false)

  const handleClick = (digit: string | string) => {
    const _digits = [...digits]
    if (digit.length === 1 && _digits.length < (digitsNo || 6))
      _digits.push(digit)
    if (digit === 'back') _digits.pop()
    setDigits(_digits)

    setTimeout(() => {
      if (_digits.length === digitsNo) setDigits([])
      onChange(_digits.join(''))
    }, 300)
  }

  if (forgot) return <ForgotCode cancel={() => setForgot(false)} />

  return (
    <Box style={BLOCKING_STYLE} bg='bg'>
      <FullCenterView>
        <Section>
          <Text>{text}</Text>
          <H1 mb='medium'>
            {[...new Array(digitsNo || digits.length)].map((_, i) => (
              <RoundButton
                key={i}
                sizeRem={1}
                bg={digits[i] ? colors.textAccent2 : colors.bg}
                mx='tiny'
                style={{ border: `1px solid ${colors.textAccent2}` }}
                data-testid='digit'
              />
            ))}
          </H1>
          <Box maxWidth='27rem'>
            {KEYBOARD.map(key => (
              <Digit
                key={key}
                m='small'
                sizeRem={7}
                onClick={() => handleClick(key + '')}
                data-testid={`button-${key}`}
              >
                {key}
              </Digit>
            ))}
          </Box>

          <RowView mt='big'>
            {options.includes('goback') && (
              <Box display='inline-block' flex={1} onClick={() => navigate(-1)}>
                Go back
              </Box>
            )}
            {options.includes('forgot') && (
              <Box
                display='inline-block'
                flex={1}
                onClick={() => setForgot(true)}
              >
                Forgot code?
              </Box>
            )}
            {options.includes('cancel') && (
              <Box
                display='inline-block'
                flex={1}
                data-testid='button-back'
                onClick={() => handleClick('back')}
              >
                Cancel
              </Box>
            )}
          </RowView>
        </Section>
      </FullCenterView>
    </Box>
  )
}

const Digit = styled(RoundButton)`
  transition-duration: 0.1s;
  :active {
    background: ${colors.accent4};
  }
`
