import React, { useState } from 'react'
import { Box, BLOCKING_STYLE } from 'components/primitives/Box'
import { H1, Text } from 'components/primitives/Text'
import { FullCenterView, ColumnView } from 'components/primitives/Views'
import { Button } from 'components/primitives/Button'
import { Restart } from '../shared/Restart'
import { STORAGE, STORAGE_WIPED } from 'store/persist'

interface Props {
  cancel: () => void
}

type Mode = 'view' | 'confirm' | 'wiped'

export const ForgotCode: React.FC<Props> = ({ cancel }) => {
  const [mode, setMode] = useState<Mode>('view')

  const wipe = () => {
    if (mode === 'view') return setMode('confirm')
    setMode('wiped')

    if (typeof localStorage === 'undefined') return // SSR
    const state = localStorage?.getItem(STORAGE)
    localStorage?.removeItem(STORAGE)
    localStorage?.setItem(STORAGE_WIPED, state || '')
  }

  if (mode === 'wiped') return <Restart />

  return (
    <Box style={BLOCKING_STYLE} bg='bg'>
      <FullCenterView>
        <Box maxWidth='30rem'>
          <H1>Forgot your code?</H1>
          <Text color='textAccent2'>
            If you forgot the PIN code, you can <b>wipe all your data</b>.
            You&apos;ll be able to import your backup files in settings. This
            action <b>cannot be undone</b>!
          </Text>
          <ColumnView>
            <Button variant='clearDestruct' onClick={wipe}>
              {mode === 'confirm' ? 'Are you sure?' : 'Wipe my data'}
            </Button>
            <Button variant='clear' onClick={cancel}>
              Nevermind
            </Button>
          </ColumnView>
        </Box>
      </FullCenterView>
    </Box>
  )
}
