import React from 'react'
import { Millis, DAY, getTodayMillis, roundToNearestDay } from 'services/time'
import { Box } from 'components/primitives/Box'
import styled from 'styled-components'
import { RowView } from 'components/primitives/Views'
import { CalendarDot } from './CalendarDot'
import { Stats } from 'services/stats/generate'

interface Props {
  days: number // days to render
  end?: Millis
  small?: boolean
  color?: string
  dontLink?: boolean
  map: Stats<string>
  substance?: string
}

export const Calendar: React.FC<Props> = React.memo(
  ({
    days,
    end = getTodayMillis(),
    small,
    color,
    dontLink,
    map,
    substance
  }) => {
    const start = end - days * DAY

    return (
      <>
        {[...new Array(days)].map((_, i) => {
          const day = roundToNearestDay(start + (i + 1) * DAY)
          return (
            <CalendarDot
              key={i}
              color={color}
              day={day}
              small={small}
              dontLink={dontLink}
              takenToday={map[day]}
              substance={substance}
            />
          )
        })}
      </>
    )
  }
)

export const WeekCalendarWrapper = styled(RowView)`
  flex-wrap: wrap;
  margin-top: 1.2rem;

  ${Box} {
    flex: 0 1 14.2%;
  }
`
