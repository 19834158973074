import React from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { ColumnView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { release } from 'services/sentry'
import { Setting } from '../shared/Setting'
import { RouteComponentProps } from '@reach/router'
import { Changelog } from './Changelog'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { GA, SETTINGS } from 'services/analytics'
import { colors } from 'services/theme'
import { isIOS } from 'services/device'

export const About: React.FC<RouteComponentProps> = () => {
  const open = (page: string) => {
    GA.event({
      category: SETTINGS,
      action: 'OpenAbout',
      label: page
    })

    let url = `https://doseapp.io/${page}`
    if (page === 'instagram') url = 'https://instagram.com/dosetracking'
    if (page === 'twitter') url = 'https://twitter.com/dosetracking'
    window.open(url)
  }

  return (
    <MenuScreen>
      <ColumnView>
        <Section mt='big'>
          <HeaderBack centered>About</HeaderBack>
          <Setting name='Version' mode='link'>
            {release}
          </Setting>
          {isIOS() && (
            <Setting
              name='Donate'
              chevron
              onClick={() => open('donate')}
              style={{ color: colors.color2 }}
            />
          )}
          <Setting
            name='Terms of Use'
            chevron
            onClick={() => open('terms-of-use')}
          />
          <Setting
            name='Privacy Policy'
            chevron
            onClick={() => open('privacy-policy')}
          />
          <Setting name='Instagram' chevron onClick={() => open('instagram')} />
          <Setting name='Twitter' chevron onClick={() => open('twitter')} />
        </Section>
        <Changelog />
      </ColumnView>
    </MenuScreen>
  )
}
