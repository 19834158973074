import React from 'react'
import { Setting } from '../shared/Setting'
import { useDispatch } from 'react-redux'
import { useSelector } from 'store/store'
import { getWeekStart } from 'store/reducers/app/app.selectors'
import { setWeekStart } from 'store/reducers/app/app.reducer'
import { Select } from '../shared/Select'

export const Weekday: React.FC = () => {
  const dispatch = useDispatch()
  const weekStart = useSelector(getWeekStart)

  return (
    <Setting name='Week Start'>
      <Select
        options={['mon', 'sun']}
        value={weekStart}
        onChange={v => dispatch(setWeekStart(v as 'mon' | 'sun'))}
      />
    </Setting>
  )
}
