import React, { useState } from 'react'
import { YearCalendar as Year } from 'components/App/shared/Calendar/YearCalendar/YearCalendar'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { LinkTabs } from './LinkTabs'
import { RouteComponentProps } from '@reach/router'
import { YearSelector } from '../shared/Calendar/YearCalendar/YearSelector'
import { parseCalendarId } from 'services/time'
import { Loading } from '../shared/Loading'
import { useLoading } from '../shared/hooks'

export const YearCalendar: React.FC<RouteComponentProps> = React.memo(() => {
  const [, urlYear] = parseCalendarId(window.location.hash)
  const [year, setYear] = useState(
    parseInt(urlYear || '') || new Date().getFullYear()
  )
  const loading = useLoading()

  return (
    <MenuScreen>
      <LinkTabs />
      <YearSelector year={year} setYear={setYear} />
      {loading ? <Loading compact /> : <Year year={'' + year} />}
    </MenuScreen>
  )
})
