import { RootState } from 'store/store'
import { MODALS } from './modals'

export const getModals = (state: RootState) => state.modals.modals

export const isCardBackground = (state: RootState) => {
  // cant get mock working :/
  const modals =
    process.env.NODE_ENV === 'test'
      ? (({
        A: { options: { cardBackground: true } },
        B: { options: {} }
      } as unknown) as typeof MODALS)
      : MODALS
  return !!state.modals.modals.find(m => modals[m.type].options.cardBackground)
}
