import React from 'react'
import { useSelector } from 'store/store'
import { getModals } from 'store/reducers/modals/modals.selectors'
import styled from 'styled-components'
import { Box } from 'components/primitives/Box'
import { MODALS } from 'store/reducers/modals/modals'
import { Transition, TransitionGroup } from 'react-transition-group'
import { useDispatch } from 'react-redux'
import { hideModal } from 'store/reducers/modals/modals.slice'

export const opacityTransition = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0, display: 'none' },
  unmounted: {}
}

export const instantInOpacityTransition = {
  ...opacityTransition,
  entering: { opacity: 1 }
}

const noBgStyles = {
  entering: {},
  entered: {},
  exiting: {},
  exited: { display: 'none' },
  unmounted: {}
}

export const Modals: React.FC = () => {
  const dispatch = useDispatch()
  const modals = useSelector(getModals)
  const hasBg = !!modals.find(m => MODALS[m.type].options.background)

  return (
    <Transition in={!!modals.length} timeout={300}>
      {state => (
        <Background
          style={(hasBg ? instantInOpacityTransition : noBgStyles)[state]}
          hasBg={hasBg}
        >
          <TransitionGroup component={React.Fragment}>
            {modals.map(modal => {
              const { Component } = MODALS[modal.type]
              const closeModal = () => dispatch(hideModal(modal.type))
              return (
                <Component
                  {...modal.props}
                  key={modal.created}
                  closeModal={closeModal}
                />
              )
            })}
          </TransitionGroup>
        </Background>
      )}
    </Transition>
  )
}

interface BackgroundProps {
  hasBg?: boolean
}

const Background = styled(Box)<BackgroundProps>`
  ${props => props.hasBg && `background: var(--modalBg);`}
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 300ms ease-in-out;
  z-index: 100;
`
