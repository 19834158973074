import { Dose } from 'services/substances/helpers/Substance'
import { roundToNearestDay, Millis, DAY } from 'services/time'
import {
  MONTH_INITIALS,
  DAYS_SHORT,
  MONTH_SHORT
} from 'components/App/shared/Calendar/YearCalendar/Month'
import { Label } from 'components/App/Profile/graphs/Graph/AxisLabel'
import { getSubstanceId } from 'components/App/Substance/Overview/Dose'

export type Range = 'daily' | 'weekly' | 'weeklysun' | 'monthly'
export type Stats<T> = { [key: number]: T[] }

export interface Graph<T> {
  axis: T[][]
  labels: Label[]
}
export type GraphGen<T> = (stats: Stats<T>, start?: Millis) => Graph<T>

export const generateStatsFromDoses = <T>(
  doses: Dose[],
  range: Range,
  modifier?: (dose: Dose) => T
) => {
  const stats: { [key: number]: T[] } = {}

  doses.forEach(dose => {
    const key = _getRangeKey(dose, range)
    const stat: T = modifier ? modifier(dose) : ((dose as unknown) as T)

    if (!stats[key]) stats[key] = [stat]
    else stats[key].push(stat)
  })

  return stats
}

export const generateCalendarMap = (doses: Dose[]) =>
  generateStatsFromDoses(doses, 'daily', d => getSubstanceId(d.id))

const _getRangeKey = (dose: Dose, range: Range): Millis => {
  const d = new Date(dose.time)
  d.setHours(0)

  const nearestDay = () => roundToNearestDay(d.getTime())

  if (range === 'daily') return nearestDay()

  if (range === 'weekly' || range === 'weeklysun') {
    let days = new Date(nearestDay()).getDay()
    if (days <= 0 && range === 'weekly') days = 7

    const millisSun = new Date(nearestDay() - days * DAY).getTime()
    return range === 'weekly' ? millisSun + DAY : millisSun
  }

  if (range === 'monthly') {
    d.setDate(1)
    return new Date(nearestDay()).getTime()
  }

  return 0
}

export const _getMonthYearAgo = (date?: Date) => {
  const d = date || new Date()
  d.setDate(1)
  d.setHours(0)
  d.setUTCFullYear(d.getUTCFullYear() - 1)
  return new Date(roundToNearestDay(d.getTime()))
}

export const generateYearGraph = <T>(stats: Stats<T>, start?: Millis) => {
  const labels = []
  const axis = []
  const date = start ? new Date(start) : _getMonthYearAgo()

  for (let i = 0; i < 12; i++) {
    date.setMonth(date.getMonth() + 1)

    labels.push(MONTH_INITIALS[date.getMonth()])
    axis.push(stats[roundToNearestDay(date.getTime())] || [])
  }

  return { axis, labels }
}

const _getDaysAgo = (days: number, date?: Date) => {
  const d = date || new Date()
  d.setHours(0)
  d.setDate(d.getDate() - days)
  return new Date(roundToNearestDay(d.getTime()))
}

export const _getWeekAgo = (date?: Date) => _getDaysAgo(6, date)
export const _get8WeeksAgo = (sunday: boolean, date?: Date) => {
  const d = date || new Date()
  // get start of the current week
  const day = d.getDay() || 7
  if (day !== 1) d.setHours(-24 * (day - 1))

  if (sunday) d.setTime(d.getTime() - DAY)

  return _getDaysAgo(7 * 7, d)
}

export const generateWeekGraph = <T>(stats: Stats<T>, start?: Millis) => {
  const labels = []
  const axis = []
  const date = start ? new Date(start) : _getWeekAgo()

  for (let i = 0; i < 7; i++) {
    date.setDate(date.getDate() + 1)

    labels.push({ text: date.getDate(), sub: DAYS_SHORT[date.getDay()] })
    axis.push(stats[roundToNearestDay(date.getTime())] || [])
  }

  return { axis, labels }
}

export const generate2MonthGraph = <T>(stats: Stats<T>, start?: Millis) => {
  const labels = []
  const axis = []
  const date = start ? new Date(start) : _get8WeeksAgo(false)

  for (let i = 0; i < 8; i++) {
    labels.push({ text: date.getDate(), sub: MONTH_SHORT[date.getMonth()] })
    axis.push(stats[roundToNearestDay(date.getTime())] || [])

    date.setDate(date.getDate() + 7)
  }

  return { axis, labels }
}

export const generateGraph = {
  monthly: generateYearGraph,
  daily: generateWeekGraph,
  weekly: generate2MonthGraph,
  weeklysun: <T>(stats: Stats<T>) =>
    generate2MonthGraph(stats, _get8WeeksAgo(true).getTime())
}
