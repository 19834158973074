import React, { useState } from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { ColumnView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { RouteComponentProps } from '@reach/router'
import { release } from 'services/sentry'
import moment from 'moment'
import { H2, Text } from 'components/primitives/Text'
import { Setting } from '../shared/Setting'
import { getUserData, STORAGE } from 'store/persist'
import { Restart } from 'components/App/shared/Restart'
import { colors } from 'services/theme'
import { isIOS } from 'services/device'

const download = (filename: string, text: string) => {
  if (typeof document === 'undefined') return // SSR
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/json;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export const readFile = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = e => {
      let backup
      try {
        backup = JSON.parse(e.target?.result?.toString() || '')
      } catch (err) {
        reject()
      }
      if (typeof backup?.state?.app === 'object') {
        backup.state.app.locked = false
        backup.state.app.pinCode = null
        return resolve(backup.state)
      }
      reject()
    }
  })

type ImportState = 'none' | 'error' | 'imported'

export const YourData: React.FC<RouteComponentProps> = () => {
  const [importState, setImportState] = useState<ImportState>('none')
  const [migrationText, setMigrationText] = useState('')

  const [exportClicks, setExportClicks] = useState(0)

  const downloadBackup = () => {
    // ensure drugs will not be disabled on App Store iOS
    const state = getUserData()
    if (state.app) state.app.explicitContent = true

    download(
      `dose-backup-${moment().format('DDMMYY')}.json`,
      JSON.stringify({
        version: release,
        state
      })
    )
  }

  const androidBackup = async () => {
    setMigrationText('Please wait...')
    const state = getUserData()

    const res = await fetch(`https://backup.doseapp.io/${state.app.user.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        version: release,
        state
      })
    })
    if (!res.ok) return setMigrationText('Failed to generate backup')
    const json = await res.json()
    if (!json.ok) return setMigrationText('Failed to generate backup')
    setMigrationText(
      `Please navigate to backup.doseapp.io/${json.url} to download your data`
    )
  }

  const importBackup = async (e: React.FormEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0]
    if (!file) return

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let state: any = null
    try {
      state = await readFile(file)
    } catch (err) {
      return setImportState('error')
    }

    if (typeof window !== 'undefined')
      window.localStorage?.setItem(STORAGE, JSON.stringify(state))
    setImportState('imported')
  }

  if (importState === 'imported') return <Restart />

  return (
    <MenuScreen>
      <ColumnView>
        <Section my='big'>
          <HeaderBack centered subtext='Settings'>
            Your Data
          </HeaderBack>
          <H2 mt='medium' onClick={() => setExportClicks(exportClicks + 1)}>
            Export your data
          </H2>
          <Setting
            name='Download data backup'
            description='This will generate a JSON file with all your personal data'
            chevron
            onClick={downloadBackup}
          />
          {isIOS() && (
            <Text>
              Notice: You have to click &quot;Save to Files&quot; and choose a
              location
            </Text>
          )}
          {exportClicks >= 7 && (
            <>
              <Setting
                name='Migrate android save'
                chevron
                style={{ color: colors.destructive }}
                onClick={androidBackup}
              />
              <Text>{migrationText}</Text>
            </>
          )}

          <H2 mt='medium'>Import backup</H2>

          <input
            type='file'
            name='file'
            id='file'
            accept='application/JSON'
            style={{ display: 'none' }}
            onChange={importBackup}
          />
          <label htmlFor='file'>
            <Setting
              name='Import from a file'
              description='Your data will be reset and imported from the file you provide. Lock
              screen settings will be reset. This <b>cannot</b> be reversed!'
              chevron
            />
          </label>
          {importState === 'error' && (
            <Text color='destructive'>
              <br />
              Failed to import your backup, did you select the right file?
            </Text>
          )}
        </Section>
      </ColumnView>
    </MenuScreen>
  )
}
