import React from 'react'
import { Box } from 'components/primitives/Box'
import styled, { css } from 'styled-components'
import { RowView } from 'components/primitives/Views'
import { Text } from 'components/primitives/Text'
import { colors, space } from 'services/theme'
import ToggledOn from 'assets/icons/fa/toggle-on-duotone.svg'
import ToggledOff from 'assets/icons/fa/toggle-off-duotone.svg'
import Chevron from 'assets/icons/fa/chevron-right-regular.svg'
import { navigate } from '@reach/router'

type Toggle = {
  mode: 'toggle'
  value: boolean
  set: (v: boolean) => void
}

type Link = {
  mode?: 'link'
  href?: string
  chevron?: boolean
}

type Props = {
  name: string
  description?: string
  onClick?: () => void
  style?: React.CSSProperties
} & (Toggle | Link)

export const Setting: React.FC<Props> = ({
  name,
  description,
  mode = 'link',
  children,
  onClick,
  style,
  ...props
}) => {
  const redirect = () =>
    (props as Link).href && navigate((props as Link).href || '/app')

  const hover = mode === 'link' && (props as Link).chevron

  return (
    <SettingBox hover={hover} onClick={onClick || redirect} style={style}>
      <RowView>
        <Text flex={1}>
          {name}
          {description && (
            <Box maxWidth='25rem'>
              <Text
                fontSize='small'
                color='textAccent2'
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </Box>
          )}
        </Text>

        {mode === 'toggle' && (
          <Toggle
            mode={mode}
            value={(props as Toggle).value}
            set={(props as Toggle).set}
          />
        )}

        {mode === 'link' && (
          <Link
            mode={mode}
            href={(props as Link).href}
            chevron={(props as Link).chevron}
          >
            {children}
          </Link>
        )}
      </RowView>
    </SettingBox>
  )
}

export const Toggle: React.FC<Toggle> = ({ value, set }) => {
  const toggle = () => set(!value)

  if (value)
    return (
      <ToggledOn
        data-testid='on'
        height='3rem'
        fill={colors.color1}
        onClick={toggle}
      />
    )
  return (
    <ToggledOff
      data-testid='off'
      height='3rem'
      fill={colors.textAccent2}
      onClick={toggle}
    />
  )
}

export const Link: React.FC<Link> = ({ chevron, children }) => {
  return (
    <>
      <Text color='textAccent2'>{children}</Text>
      {chevron && (
        <Chevron
          data-testid='chevron'
          height='1rem'
          fill={colors.textAccent2}
          style={{ margin: `${space.small} 0` }}
        />
      )}
    </>
  )
}

interface SettingBoxProps {
  hover?: boolean
}

const SettingBox = styled(Box)<SettingBoxProps>`
  border-bottom: 1px solid ${colors.accent3};
  transition-duration: 0.05s;

  ${props =>
    props.hover &&
    css`
      &:active {
        background: ${colors.accent2};
      }
    `}
`

SettingBox.defaultProps = {
  bg: 'var(--card-page-bg)',
  padding: 'pmedium' //psmall
}
