import React, { useState } from 'react'
import { Text } from 'components/primitives/Text'
import { calendarTime, MINUTE } from 'services/time'
import { TextInput } from 'components/primitives/Input'
import Edit from 'assets/icons/fa/edit-solid.svg'
import { colors } from 'services/theme'

interface Props {
  time: number
  setTime: (time: number) => void
}

export const Time: React.FC<Props> = ({ time, setTime }) => {
  const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * MINUTE
  const [editing, setEditing] = useState(false)

  if (!editing)
    return (
      <Text color='textAccent2' onClick={() => setEditing(true)}>
        {calendarTime(time)} <Edit fill={colors.textAccent1} height='1.4rem' />
      </Text>
    )

  const currentDate = new Date(time - TIMEZONE_OFFSET)
    .toISOString()
    .split('.')[0]

  return (
    <TextInput
      type='datetime-local'
      min='1990-01-01T00:00'
      max='2030-01-01T00:00'
      autoFocus
      data-testid='time-input'
      defaultValue={currentDate}
      onBlur={e => {
        setEditing(false)
        setTime(new Date(e.target.value).getTime())
      }}
    />
  )
}
