import React from 'react'
import { RoundedBox } from 'components/primitives/Box'
import { H2 } from 'components/primitives/Text'
import { Calendar, WeekCalendarWrapper } from '../shared/Calendar/Calendar'
import { getWeekEnd, DAY, getMonthId } from 'services/time'
import { navigate } from '@reach/router'
import { useSelector } from 'store/store'
import { getWeekStart } from 'store/reducers/app/app.selectors'
import { isSmall } from 'services/device'
import { Stats } from 'services/stats/generate'

interface Props {
  map: Stats<string>
}

// UPDATE HERE
export const SummaryCalendar: React.FC<Props> = React.memo(({ map }) => {
  const weekStart = useSelector(getWeekStart)
  const startAtSun = weekStart === 'sun' ? -DAY : 0

  return (
    <RoundedBox
      boxShadow='bottom'
      p='small'
      pb={0}
      my='medium'
      onClick={() => navigate('/app/calendar#' + getMonthId())}
    >
      <H2 ml='0.5rem'>Calendar</H2>
      <WeekCalendarWrapper>
        <Calendar
          dontLink
          map={map}
          days={isSmall() ? 21 : 28}
          end={getWeekEnd() + startAtSun}
        />
      </WeekCalendarWrapper>
    </RoundedBox>
  )
})
