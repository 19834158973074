import React from 'react'
import { RoundedBox } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'

interface Props {
  title: string
}

export const Card: React.FC<Props> = ({ children, title }) => {
  return (
    <>
      <Text as='p' textAlign='center' fontWeight='medium' fontSize='1.6rem'>
        {title}
      </Text>
      <RoundedBox boxShadow='bottom' p='small' mb='medium' mt='tiny'>
        {children}
      </RoundedBox>
    </>
  )
}
