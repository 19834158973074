import React, { useState } from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { themes } from '../../services/theme'
import { useSelector } from '../../store/store'
import { Debug } from './Debug/Debug'
import { Dashboard } from './Dashboard/Dashboard'
import { Setup } from './Setup/Setup'
import {
  getIsSetupFinished,
  getIsLocked,
  getTheme,
  getUserGoals,
  getUser
} from 'store/reducers/app/app.selectors'
import { navigate, Router, Location } from '@reach/router'
import { ChooseSubstance } from './Substance/ChooseSubstance/ChooseSubstance'
import { AddQuickRoute } from './Substance/AddDose/AddQuick/AddQuick'
import { AddCustomRoute } from './Substance/AddDose/AddCustom/AddCustom'
import { Error404 } from './Error404/Error404'
import { Overview } from './Substance/Overview/Overview'
import { General } from './Settings/General/General'
import { About } from './Settings/About/About'
import { Substance } from './Settings/Substances/Substance/Substance'
import { SubstanceList } from './Settings/Substances/SubstanceList/SubstanceList'
import { AddQuickDose } from './Settings/Substances/AddQuickDose/AddQuickDose'
import { YearCalendar } from './Dashboard/YearCalendar'
import { Calendar } from './Substance/Calendar/Calendar'
import { registerPageview, registerLaunch } from 'services/analytics'
import { Day } from './Dashboard/Day/Day'
import { Menu } from './shared/Menu/Menu'
import { CreateSubstance } from './Substance/CreateSubstance/CreateSubstance'
import { PinLock } from './PinLock/PinLock'
import { PinSet } from './PinLock/PinSet'
import { Lockscreen } from './Settings/Lockscreen/Lockscreen'
import { DoseInfo } from './Substance/DoseInfo/DoseInfo'
import { EditDose } from './Substance/EditDose/EditDose'
import { YourData } from './Settings/Data/YourData'
import { GlobalStyles } from '../shared/GlobalStyles'
import { NeedHelp } from './Settings/NeedHelp/NeedHelp'
import Preferences from './Settings/Preferences/Preferences'
import { isSSR } from 'services/device'
import { Loading } from './shared/Loading'
import { Modals } from './shared/Modals/Modals'
import { CardBackground } from './shared/CardBackground'
import { Profile } from './Profile/Profile'
import { Reminders } from './Settings/Reminders/Reminders'
import { useDispatch } from 'react-redux'
import { showModal } from 'store/reducers/modals/modals.slice'
import { addUserGoal } from 'store/reducers/app/app.reducer'
import { REMINDERS } from './shared/notices/Reminders'

const Root: React.FC = () => {
  const isSetupFinished = useSelector(getIsSetupFinished)
  const isLocked = useSelector(getIsLocked)
  const [loaded, setLoaded] = useState(false)

  React.useEffect(() => {
    if (!isSetupFinished) navigate('/app/setup')
  }, [isSetupFinished])

  React.useEffect(() => {
    try {
      navigator?.serviceWorker?.getRegistration().then(r => r?.update())
    } catch (err) {
      console.log('[sw] failed to force an update', err)
    }

    registerLaunch()
    setLoaded(true)
  }, [])

  return (
    <Theme>
      <GlobalStyles />
      {(isSSR() || !loaded) && <Loading />}
      {isLocked && <PinLock />}
      <Modals />
      <ShowNotices />
      <AnimatedRouter locked={isLocked}>
        <Error404 default />
        <Dashboard path='/app' />
        <YearCalendar path='/app/calendar' />
        <Day path='/app/day/:day' />
        <Setup path='/app/setup' />
        <Debug path='/app/debug' />
        <General path='/app/settings' />
        <Lockscreen path='/app/settings/lockscreen' />
        <Preferences path='/app/settings/preferences' />
        <NeedHelp path='/app/settings/need-help' />
        <PinSet path='/app/settings/lockscreen/set-pin/:length' />
        <SubstanceList path='/app/settings/substances' />
        <Substance path='/app/settings/substances/:id' />
        <AddQuickDose path='/app/settings/substances/:id/add-quick' />
        <YourData path='/app/settings/data' />
        <About path='/app/settings/about' />
        <Reminders path='/app/settings/reminders' />
        <ChooseSubstance
          path='/app/add-dose'
          href='/app/substance/:id/add-dose'
        />
        <CreateSubstance path='/app/create-substance/:suggestedName' />
        <Calendar path='/app/substance/:id/calendar' />
        <Overview path='/app/substance/:id' />
        <DoseInfo path='/app/substance/dose/:id' />
        <EditDose path='/app/substance/dose-edit/:id' />
        <AddQuickRoute path='/app/substance/:id/add-dose' />
        <AddCustomRoute path='/app/substance/:id/add-dose/custom' />
        <Profile path='/app/profile' />
      </AnimatedRouter>
    </Theme>
  )
}

export default Root

export const Aux = React.forwardRef((props, ref: React.Ref<HTMLDivElement>) => (
  <div ref={ref}>{props.children}</div>
))

interface AnimatedRouterProps {
  locked?: boolean
}

export const AnimatedRouter: React.FC<AnimatedRouterProps> = props => (
  <Location>
    {({ location }) => (
      <>
        <ViewRegsterer location={location.key} />
        <CardBackground>
          <Router
            location={location}
            className='router'
            component={Aux as React.FC}
          >
            {props.children}
          </Router>
        </CardBackground>
        {!props.locked && <Menu />}
      </>
    )}
  </Location>
)

export const prefersDark =
  typeof window !== 'undefined' &&
  window.matchMedia('(prefers-color-scheme: dark)').matches

const Theme: React.FC = ({ children }) => {
  let theme = useSelector(getTheme)

  if (theme === 'default') theme = prefersDark ? 'dark' : 'light'
  if (typeof window === 'undefined') theme = 'ssr'

  return (
    <ThemeProvider theme={themes[theme]}>
      <AppStyles />
      {children}
    </ThemeProvider>
  )
}

const AppStyles = createGlobalStyle`
  body {
    -webkit-overflow-scrolling: auto;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    overflow: hidden;
  }
`

interface ViewRegistererProps {
  location: string | undefined
}

const ViewRegsterer: React.FC<ViewRegistererProps> = React.memo(() => {
  if (typeof window == 'undefined') return null // SSR
  registerPageview()
  window.scrollTo(0, 0)
  return null
})

const ShowNotices: React.FC = React.memo(() => {
  const dispatch = useDispatch()
  const goals = useSelector(getUserGoals)
  const { since } = useSelector(getUser)
  const locked = useSelector(getIsLocked)

  const show = (type: string, userBefore?: number) => {
    if (userBefore && since > userBefore) return

    if (!goals.includes(`seenNotice:${type}`)) {
      dispatch(showModal({ type, props: {} }))
      dispatch(addUserGoal(`seenNotice:${type}`))
    }
  }

  React.useEffect(() => {
    if (locked) return
    show(REMINDERS, new Date('2020-11-08').getTime())
  }, [goals, since, locked])

  return null
})
