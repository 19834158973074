import React, { useState } from 'react'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { Section } from 'components/primitives/Section'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { AddCustom } from 'components/App/Substance/AddDose/AddCustom/AddCustom'
import { TypeTabs } from 'components/App/Substance/AddDose/TypeTabs'
import { FullCenterView } from 'components/primitives/Views'
import { useDispatch } from 'react-redux'
import { addQuickDose } from 'store/reducers/substances/substances.reducer'
import { QuickDose } from 'services/substances/helpers/Substance'
import { Error } from 'components/primitives/Input'
import { navigate, RouteComponentProps } from '@reach/router'
import { Error404 } from 'components/App/Error404/Error404'
import { CONSUMED } from 'services/substances/helpers/constants'
import { DOSES, GA } from 'services/analytics'

interface Props {
  id: string
}

export const AddQuickDose: React.FC<RouteComponentProps<Props>> = ({
  id = ''
}) => {
  const state = useSelector(getSubstanceState(id))
  const { quickDoses, consumptionTypes, name } = state || {
    quickDoses: [],
    consumptionTypes: [CONSUMED],
    name: 'Name'
  }
  const [type, setType] = useState(consumptionTypes[0])
  const [error, setError] = useState<string | null>(null)
  const dispatch = useDispatch()

  const submit = (dose: QuickDose) => {
    if (quickDoses.find(q => q.name === dose.name))
      return setError('This name is already used')

    if (error) setError(null)
    dose.type = type
    dispatch(addQuickDose({ id: id, dose }))
    GA.event({
      category: DOSES,
      action: 'AddQuick',
      label: id
    })
    navigate(`/app/settings/substances/${id}`)
  }

  if (!state) return <Error404 />

  return (
    <MenuScreen>
      <FullCenterView>
        <Section>
          <Section>
            <HeaderBack centered subtext={name}>
              Quick Dose
            </HeaderBack>
          </Section>

          <TypeTabs
            consumptionTypes={consumptionTypes}
            selected={type}
            setSelected={setType}
          />

          <AddCustom
            type={type}
            id={id}
            submit={submit}
            dose={null}
            setDose={d => d}
            withCustomCost
          />
          {error && <Error>{error}</Error>}
        </Section>
      </FullCenterView>
    </MenuScreen>
  )
}
