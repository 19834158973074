import React, { useState } from 'react'
import { Text, H1 } from 'components/primitives/Text'
import { Section } from 'components/primitives/Section'
import tinyLogo from 'assets/logo-tiny.png'
import { Continue } from '../shared/Continue/Continue'
import { ImportData } from './ImportData'
import { Restart } from 'components/App/shared/Restart'
import { GA, APP } from 'services/analytics'

interface Props {
  next: () => void
}

export const Welcome: React.FC<Props> = ({ next }) => {
  const [restart, setRestart] = useState(false)

  React.useEffect(() => {
    GA.event({ category: APP, action: 'Setup', label: 'Welcome' })
  }, [])

  if (restart) return <Restart />

  return (
    <>
      <Section mt='big'>
        {process.env.NODE_ENV !== 'test' && <img src={tinyLogo} height={30} />}
      </Section>
      <Section mt='big'>
        <H1 mb='1rem'>Welcome!</H1>
        <Text color='textAccent1' fontSize='2.1rem'>
          Dose helps you stay on track with your recreational drug use and makes
          it easier to work towards a better, healthier lifestyle.
        </Text>

        <Continue active onClick={next} text='Get started'>
          <ImportData restart={() => setRestart(true)} />
        </Continue>
      </Section>
    </>
  )
}
