import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { MenuScreen } from 'components/App/shared/screens/MenuScreen'
import { HeaderBack } from 'components/App/shared/HeaderBack'
import { Section } from 'components/primitives/Section'
import { Button } from 'components/primitives/Button'
import { textSize, Text } from 'components/primitives/Text'
import { ColumnView } from 'components/primitives/Views'
import { GA, SETTINGS } from 'services/analytics'

export const NeedHelp: React.FC<RouteComponentProps> = () => {
  const open = (link: string) => {
    GA.event({
      category: SETTINGS,
      action: 'OpenHelp',
      label: link
    })
    window.open(link)
  }

  return (
    <MenuScreen>
      <Section my='big'>
        <ColumnView>
          <HeaderBack centered>Need help?</HeaderBack>
          <Text fontSize={textSize.big} textAlign='center'>
            Don&apos;t worry. Your bad experience will end. Click the buttons
            below if you need some help.
          </Text>

          <Button my='small' onClick={() => open('https://chat.tripsit.me/')}>
            Chat with a TripSitter
          </Button>

          <Button
            my='small'
            onClick={() =>
              open('https://wiki.tripsit.me/wiki/How_To_Deal_With_A_Bad_Trip')
            }
          >
            How to deal with a Bad Trip
          </Button>

          <Button
            my='small'
            onClick={() => open('https://youtu.be/OCuZWlx7JIo?t=199')}
          >
            Watch if Having a Bad Trip (Video)
          </Button>
        </ColumnView>
      </Section>
    </MenuScreen>
  )
}
