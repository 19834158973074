import React from 'react'
import { Box } from 'components/primitives/Box'
import { Text } from 'components/primitives/Text'
import { CenteredRowView, FullCenterView } from 'components/primitives/Views'
import { RoundButton } from 'components/primitives/Button'
import Chevron from 'assets/icons/fa/chevron-right-regular.svg'
import { colors } from 'services/theme'

interface Props {
  setYear: (year: number) => void
  year?: number
}

export const YearSelector: React.FC<Props> = ({
  year = new Date().getFullYear(),
  setYear
}) => {
  const set = (c: number) => setYear(year + c)

  return (
    <Box m='small'>
      <CenteredRowView>
        <Setter setYear={set} change={-1} />
        <Text
          fontWeight={600}
          fontSize='2.1rem'
          as='p'
          flex={1}
          textAlign='center'
        >
          {year}
        </Text>
        <Setter setYear={set} change={1} />
      </CenteredRowView>
    </Box>
  )
}

interface SetterProps {
  setYear: (change: number) => void
  change: number
}

const Setter: React.FC<SetterProps> = ({ setYear, change }) => {
  return (
    <RoundButton
      sizeRem={3}
      onClick={() => setYear(change)}
      data-testid={`change-${change}`}
    >
      <FullCenterView>
        <Chevron
          fill={colors.textAccent2}
          height={15}
          style={{
            ...(change === -1 && { transform: 'rotate(180deg)' })
          }}
        />
      </FullCenterView>
    </RoundButton>
  )
}
