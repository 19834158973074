import React from 'react'
import styled from 'styled-components'
import { Box } from 'components/primitives/Box'
import Arrow from 'assets/icons/fa/arrow-left-regular.svg'
import { ScreenContent } from './Screen'
import { colors } from 'services/theme'
import { FullCenterView } from 'components/primitives/Views'
import { Section } from 'components/primitives/Section'

interface Props {
  back?: string | (() => void)
}

export const DialogScreen: React.FC<Props> = ({ children, back }) => {
  const goBack = () => {
    if ({}.toString.call(back) === '[object Function]')
      return (back as () => void)()

    if (typeof window !== 'undefined') window.history.back()
  }

  return (
    <DialogWrapper>
      {back && (
        <Back>
          <Arrow fill={colors.text} onClick={goBack} />
        </Back>
      )}

      {children}
    </DialogWrapper>
  )
}

export const DialogWrapper = styled(ScreenContent)`
  height: 100vh;
  left: 0;
  overflow-y: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 30;

  @media screen and (max-height: 600px) {
    ${FullCenterView} > ${Section} {
      margin: auto;
      padding-top: 2rem;
      margin-bottom: 10rem;
    }
  }
`

export const Back = styled(Box)`
  left: 0;
  position: fixed;
  top: 2rem;

  svg {
    float: left;
    margin: 1.5rem;
  }
`
