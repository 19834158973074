import React, { useState } from 'react'
import { DialogScreen } from '../shared/screens/DialogScreen'
import { RouteComponentProps, navigate } from '@reach/router'
import { Section } from 'components/primitives/Section'
import { Substances } from './Substances/Substances'
import { UserGoals } from './UserGoals/UserGoals'
import { useDispatch } from 'react-redux'
import { setSetupFinished } from 'store/reducers/app/app.reducer'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Welcome } from './Welcome/Welcome'
import { GA, APP } from 'services/analytics'
import { Reminders } from './Reminders/Reminders'

export const Setup: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)
  const next = (n = 1) => setStep(step + n)

  React.useEffect(() => {
    if (step >= 4) {
      dispatch(setSetupFinished(true))
      navigate('/app')
      GA.event({ category: APP, action: 'Setup', label: 'Finished' })
    }
  }, [step])

  return (
    <DialogScreen>
      <Section m='medium'>
        <TransitionGroup className='transition-group'>
          <CSSTransition key={step} classNames='fade-setup' timeout={300}>
            <div>
              {step === 0 && <Welcome next={next} />}
              {step === 1 && <Substances next={next} />}
              {step === 2 && <UserGoals next={next} />}
              {step === 3 && <Reminders next={next} />}
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Section>
    </DialogScreen>
  )
}
