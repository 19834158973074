import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useSelector } from 'store/store'
import { getPinCode } from 'store/reducers/app/app.selectors'
import { useDispatch } from 'react-redux'
import { setLocked } from 'store/reducers/app/app.reducer'
import { PinScreen } from './PinScreen'

export const PinLock: React.FC<RouteComponentProps> = () => {
  const dispatch = useDispatch()
  const pin = useSelector(getPinCode)
  const [retries, setRetries] = useState(0)
  const digitsNo = (pin + '').length

  const handleChange = (value: string) => {
    if (value === pin) return dispatch(setLocked(false))
    if (value.length === digitsNo) return setRetries(retries + 1)
  }

  return (
    <PinScreen
      onChange={handleChange}
      digitsNo={digitsNo}
      text={retries === 0 ? 'Enter your pin code' : 'Try again'}
    />
  )
}
