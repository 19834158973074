import React from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'store/store'
import { getAllNotifications } from 'store/reducers/notifications/notifications.selectors'
import { filterActive } from 'services/notifications'
import { RoundedBox } from 'components/primitives/Box'
import styled from 'styled-components'
import { colors } from 'services/theme'
import Dismiss from 'assets/icons/fa/times-regular.svg'
import { dismissNotification } from 'store/reducers/notifications/notifications.reducer'
import { RowView } from 'components/primitives/Views'
import { isIOS } from 'services/device'

export const Notifications: React.FC = () => {
  const dispatch = useDispatch()
  const notifications = useSelector(getAllNotifications)
  const active = filterActive(notifications)

  return (
    <WithStyles>
      {(isIOS() || process.env.NODE_ENV === 'development') && (
        <RoundedBox boxShadow='bottom' p='pmedium' mt='small' radius='1rem'>
          <b>🍏 Dose is on the App Store!</b> Migrate your data to{' '}
          <a href='https://apps.apple.com/us/app/dose/id1514087697'>
            the new app
          </a>
          , as this version will no longer be supported.
        </RoundedBox>
      )}
      {active.map(notification => (
        <RoundedBox
          key={notification.id}
          boxShadow='bottom'
          p='pmedium'
          mt='small'
          radius='1rem'
        >
          <RowView>
            <div
              style={{ flex: 1 }}
              dangerouslySetInnerHTML={{ __html: notification.text }}
            />
            <Dismiss
              height='1.4rem'
              fill={colors.textAccent2}
              data-testid='dismiss'
              onClick={() => dispatch(dismissNotification(notification.id))}
            />
          </RowView>
        </RoundedBox>
      ))}
    </WithStyles>
  )
}

const WithStyles = styled.div`
  a {
    //color: ${colors.color1};
    color: ${colors.textAccent1};
    text-decoration: underline;
  }
`
