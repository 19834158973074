import React from 'react'
import { Tabs, LinkTab } from 'components/primitives/Tabs'
import { Section } from 'components/primitives/Section'
import { getMonthId } from 'services/time'

interface Props {
  id: string
}

export const LinkTabs: React.FC<Props> = ({ id }) => {
  const urlId = encodeURI(id)

  return (
    <Section my='medium'>
      <Tabs>
        <LinkTab href={`/app/substance/${urlId}`}>Overview</LinkTab>
        <LinkTab href={`/app/substance/${urlId}/calendar#${getMonthId()}`}>
          Calendar
        </LinkTab>
      </Tabs>
    </Section>
  )
}
