import React from 'react'
import styled, { css } from 'styled-components'
import { CenteredRowView } from './Views'
import { colors, fontWeights, space as spaces } from 'services/theme'
import { space, SpaceProps } from 'styled-system'
import { Link } from '@reach/router'

export const Tabs = styled(CenteredRowView)``

type TabProps = SpaceProps & { active?: boolean }
export const Tab = styled.div<TabProps>`
  ${space}
  border-bottom: 5px solid rgba(0,0,0,0);
  color: ${colors.textAccent2};
  cursor: pointer;
  font-weight: ${fontWeights.bold};
  margin: 0 ${spaces.small};
  svg {
    stroke: ${colors.textAccent2};
  }

  ${props =>
    props.active &&
    css`
      border-bottom: 5px solid ${colors.activeBorderColor};
      color: ${colors.text};
      svg {
        stroke: ${colors.text};
      }
    `}
`

interface LinkTabProps {
  href: string
}

export const LinkTab: React.FC<LinkTabProps> = ({ href, children }) => {
  const page = href.split('#')[0]
  const matches =
    typeof window !== 'undefined'
      ? window.location.pathname === page ||
      window.location.pathname === page + '/'
      : page === '/app'

  return (
    <Link to={href}>
      <Tab active={matches}>{children}</Tab>
    </Link>
  )
}
