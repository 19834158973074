import React from 'react'
import { RoundedBox, Box } from 'components/primitives/Box'
import { useSelector } from 'store/store'
import { getSubstanceState } from 'store/reducers/substances/substances.selectors'
import { H2, Text } from 'components/primitives/Text'
import {
  CenteredColumnView,
  CenteredRowView
} from 'components/primitives/Views'
import Chevron from 'assets/icons/fa/chevron-right-regular.svg'
import { colors } from 'services/theme'
import { navigate } from '@reach/router'
import { Calendar } from '../shared/Calendar/Calendar'
import { fromNow } from 'services/time'
import { getLastDose } from 'store/reducers/doses/doses.selectors'
import { substanceName } from 'services/format'
import { Stats } from 'services/stats/generate'

interface Props {
  id: string
  map: Stats<string>
}

export const SubstanceSummary: React.FC<Props> = React.memo(({ id, map }) => {
  const state = useSelector(getSubstanceState(id))
  const lastDose = useSelector(getLastDose(id))

  const openSubstance = () => navigate(`/app/substance/${id}`)

  return (
    <RoundedBox
      boxShadow='bottom'
      p='pmedium'
      my='medium'
      onClick={openSubstance}
    >
      <CenteredColumnView>
        <CenteredRowView>
          <Box flex={1}>
            <H2>{substanceName.deserialize(state.name)}</H2>
            {/* HACK */}
            <Text
              fontSize='small'
              color='textAccent1'
              style={{ top: '-1rem', position: 'relative' }}
            >
              {lastDose
                ? `Last consumed ${fromNow(lastDose.time)}`
                : 'Never consumed'}
            </Text>
            <Box maxWidth='18rem'>
              <CenteredRowView>
                <Calendar
                  days={7}
                  map={map}
                  substance={id}
                  small
                  color={state.color}
                />
              </CenteredRowView>
            </Box>
          </Box>
          <CenteredColumnView>
            <Chevron fill={colors.accent3} />
          </CenteredColumnView>
        </CenteredRowView>
      </CenteredColumnView>
    </RoundedBox>
  )
})
